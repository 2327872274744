.page-lifetime-report {

  $box-margin-right: nth($report-grid-components-spacing, 1);
  $box-margin-bottom: nth($report-grid-components-spacing, 2);

  .report-grid {
    &__container {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto repeat(3, 1fr);
      grid-template-areas:
        "household-consumption household-consumption"
        "battery-usage battery-usage-components"
        "pv-generation pv-generation-components"
        "grid-usage grid-usage-components"
      ;

      @each $breakpoint, $gap in $report-grid-gaps {
        @include media-breakpoint-up($breakpoint) {
          gap: nth($gap, 1) nth($gap, 2);
        }
        @include media-breakpoint-up(xl) {
          gap: nth($gap, 2) nth($gap, 1);
        }
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "pv-generation"
          "pv-generation-components"
          "grid-usage"
          "grid-usage-components"
          "battery-usage"
          "battery-usage-components"
      ;
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "pv-generation"
          "pv-generation-components"
          "grid-usage"
          "grid-usage-components"
          "battery-usage"
          "battery-usage-components"
      ;
      }

      &_battery-usage-components {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: "battery-to-grid battery-to-house";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "battery-to-grid battery-to-house";
        }
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          margin-left: 3rem;
          grid-template-areas: "battery-to-grid" "battery-to-house";
        }

        &:before {
          @include media-breakpoint-down(md) {
            content: '';
            position: absolute;
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            width: 1px;
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
            border: 1px solid $primary;
          }

          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - .5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }
        }

        &:after {
          @include media-breakpoint-up(lg) {
            content: '';
            position: absolute;
            top: -1 * nth(map_get($report-grid-gaps, lg), 1);
            right: $box-margin-right/ 2 * -1;
            width: 1px;
            height: calc(250% + #{$box-margin-bottom * 2} + #{nth(map_get($report-grid-gaps, lg), 1) * 3});
            border: 1px solid $white;
          }
          @include media-breakpoint-up(xl) {
            top: -1 * nth(map_get($report-grid-gaps, xl), 2);
            height: calc(250% + #{$box-margin-bottom * 2} + #{nth(map_get($report-grid-gaps, xl), 2) * 3});
          }
        }
      }

      &_pv-generation-components {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
        }
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(3, 1fr);
          margin-left: 3rem;
          grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
        }

        &:before {
          @include media-breakpoint-down(md) {
            content: '';
            position: absolute;
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            width: 1px;
            height: calc(100% - (33% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
            border: 1px solid $primary;
          }
          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(33% / 2 - .5rem);
            width: calc(100% - (33% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }
        }
      }

      &_grid-usage-components {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: "grid-to-battery grid-into-house";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "grid-to-battery grid-into-house";
        }
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          margin-left: 3rem;
          grid-template-areas: "grid-to-battery" "grid-into-house";
        }

        &:before {
          @include media-breakpoint-down(md) {
            content: '';
            position: absolute;
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            width: 1px;
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
            border: 1px solid $primary;
          }

          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - .5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }
        }
      }
    }

    &__box {
      &_components {
        @include media-breakpoint-up(lg) {
          margin-bottom: $box-margin-bottom;
          margin-right: $box-margin-right;
        }

        @include media-breakpoint-only(lg) {
          margin-right: $box-margin-bottom;
        }

        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }

        &:before {
          @include media-breakpoint-up(lg) {
            content: '';
            position: absolute;
            bottom: -1 * $box-margin-bottom / 2;
            left: -1 * nth(map_get($report-grid-gaps, lg), 1);
            width: calc(70% + #{$box-margin-right / 2} + .5rem - #{nth(map_get($report-grid-gaps, lg), 2) / 2} + 2px);
            height: 1px;
            border: 1px solid $primary;
          }
          @include media-breakpoint-up(xl) {
            left: -1 * nth(map_get($report-grid-gaps, xl), 1);
            width: calc(75% + #{$box-margin-right / 2} - #{nth(map_get($report-grid-gaps, xl), 2) / 2});
          }
        }

        &.report-grid__container_pv-generation-components {
          &:before {
            @include media-breakpoint-up(lg) {
              width: calc(200% / 3 + 17.5% - 10px + #{nth(map_get($report-grid-gaps, lg), 2)});
            }
            @include media-breakpoint-up(xl) {
              width: calc(200% / 3 + 17.5% - 0.5rem - 12px + #{nth(map_get($report-grid-gaps, xl), 2)});
            }
            @include media-breakpoint-up(xxl) {
              width: calc(200% / 3 + 17.5% - 0.5rem - 3px + #{nth(map_get($report-grid-gaps, xl), 2)});
            }
          }
        }
      }

      &_household-consumption {
        &:before {
          @include media-breakpoint-only(md) {
            $dotSize: map_get($report-block-dot-sizes, lg);
            @include report-block-make-dot(lg, $white, true);
            content: '';
            position: absolute;
            bottom: 0;
            right: 1.65rem;
            margin-right: -1 * $dotSize / 2;
            margin-bottom: -1 * $dotSize / 2;
            z-index: 1;
          }
        }
      }

      &_battery-usage,
      &_pv-generation,
      &_grid-usage {
        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }

        &:before {
          $dotSize: map_get($report-block-dot-sizes, lg);
          @include report-block-make-dot(lg, $primary, true);
          content: '';
          position: absolute;
          left: 1.5rem;
          margin-left: -1 * $dotSize / 2;
          bottom: -1 * $dotSize / 2;
          z-index: 1;
          $dotSize: map_get($report-block-dot-sizes, xl);
          @include media-breakpoint-only(md) {
            @include report-block-make-dot(xl, $primary, false);
            left: auto;
            right: 50%;
            margin-right: -1 * $dotSize / 2;
            bottom: -1 * $dotSize / 2;
          }
          @include media-breakpoint-up(lg) {
            $dotSize: map_get($report-block-dot-sizes, xl);
            @include report-block-make-dot(xl, $primary, false);
            left: auto;
            right: - 1 * $dotSize / 2;
            bottom: $box-margin-bottom / 2;
            margin-right: 0;
            margin-bottom: -1 * $dotSize / 2;
          }
        }
        &:after {
          @include media-breakpoint-only(md) {
            $height: nth(map_get($report-grid-gaps, md), 1) / 3 * 2;
            content: '';
            position: absolute;
            bottom: -1 * $height;
            right: calc(50% - 1px);
            width: 1px;
            height: $height;
            border: 1px solid $primary;
          }
        }
      }

      &_battery-to-grid,
      &_battery-to-house,
      &_charged-battery,
      &_pv-export-to-grid,
      &_pv-into-house,
      &_grid-to-battery,
      &_grid-into-house {
        &:before {
          $dotSize: map_get($report-block-dot-sizes, base);
          @include media-breakpoint-down(md) {
            @include report-block-make-dot(base, $primary, true);
            content: '';
            position: absolute;
            left: - 1 * $dotSize / 2;
            top: 50%;
            margin-top: -1 * $dotSize / 2;
            z-index: 1;
          }

          @include media-breakpoint-only(md) {
            left: 50%;
            top: 0;
            margin-left: -1 * $dotSize / 2;
          }

          @include media-breakpoint-up(lg) {
            content: '';
            position: absolute;
            bottom: -1 * $box-margin-bottom / 2;
            left: 50%;
            width: 1px;
            height: $box-margin-bottom / 2;
            border: 1px solid $primary;
          }
        }

        &:after {
          @include media-breakpoint-down(md) {
            $width: 1.5rem;
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: -1 * $width;
            width: $width;
            height: 1px;
            border: 1px solid $primary;
          }

          @include media-breakpoint-only(md) {
            $height: nth(map_get($report-grid-gaps, md), 1) / 3;
            left: 50%;
            width: 1px;
            height: $height;
            top: -1 * $height;
            margin-left: -1px;
          }
        }
      }
    }
  }

  .report-block {
    &__title {
      padding-right: 2.5rem;

      &_lg {
        font-size: 1.5rem;

        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
          margin: 0 0 0 3.5rem;
          padding: 0;
        }

        br {
          @include media-breakpoint-down(md) {
            display: none !important;
          }
        }
      }
    }

    &__description {
      margin-top: 1rem;

      &_lg {
        margin-top: 2rem;
        flex-grow: 1;

        @include media-breakpoint-down(md) {
          margin-top: 1rem;
        }
      }

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }

    &__icon {
      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }

      &_lg {
        @include media-breakpoint-down(lg) {
          font-size: 3rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 2rem;
          position: absolute;
        }
      }

      &_household-consumption {
        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
          top: map_get($report-block-spacing-y, xs);
        }
        @include media-breakpoint-down(sm) {
          left: map_get($report-block-spacing-y, xs);
        }
      }
    }

    &__energy {
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }

      &_household-consumption {
        @include media-breakpoint-only(md) {
          margin: 0;
        }
        @include media-breakpoint-down(sm) {
          margin-right: auto;
        }
      }
    }

    &_household-consumption {
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &:before {
        @include media-breakpoint-only(md) {
          $height: 100% * 14;
          content: '';
          position: absolute;
          right: 1.5rem;
          margin-left: -1px;
          bottom: calc(-1 * #{$height} + 1rem);
          height: calc(#{$height} - 1rem);
          width: 1px;
          border: 1px solid $white;
        }

        @include media-breakpoint-up(lg) {
          $dotSize: map_get($report-block-dot-sizes, lg);
          @include report-block-make-dot(lg, $white);
          content: '';
          position: absolute;
          bottom: -1 * $dotSize / 2;
          right: $box-margin-right / 2;
          margin-right: -1 * $dotSize / 2;
        }

        @include media-breakpoint-only(lg) {
          right: $box-margin-right / 4;
        }
      }
    }

    &_to-household-consumption {
      border-color: $white;

      $dotSize: map_get($report-block-dot-sizes, lg);
      &:before {
        @include media-breakpoint-up(lg) {
          @include report-block-make-dot(lg, $white);
          content: '';
          position: absolute;
          right: - 1 * $dotSize / 2;
          top: 50%;
          margin-top: -1 * $dotSize / 2;
        }
      }
      &:after {
        @include media-breakpoint-only(md) {
          $width: 1.5rem;
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -1px;
          right: -1 * $width;
          width: $width;
          height: 1px;
          border: 1px solid $white;
        }
        @include media-breakpoint-up(lg) {
          content: '';
          position: absolute;
          top: 50%;
          right: $box-margin-right / 2 * -1;
          margin-top: -1px;
          width: $box-margin-right / 2;
          height: 1px;
          border: 1px solid $white;
        }
      }
    }
  }

}