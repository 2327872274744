@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import '~rc-time-picker/assets/index.css';
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --electric: #e3f3f7;
  --primary: #44aeca;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #fc1e1e;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: "neue-haas-unica", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
  text-align: left;
  background-color: #000;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #d9d9d9;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbe8f0;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9ed5e3;
}

.table-hover .table-primary:hover {
  background-color: #b7dfea;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7dfea;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fec0c0;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fd8a8a;
}

.table-hover .table-danger:hover {
  background-color: #fea7a7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fea7a7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.0625em + 1.75rem + 2px);
  padding: 0.875rem 0.875rem;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.0625;
  color: #fff;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #44aeca;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #fff;
  background-color: #000;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 none;
}
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #000;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #fff;
  background-color: #000;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.0625;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  margin-bottom: 0;
  line-height: 1.0625;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fc1e1e;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(252, 30, 30, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fc1e1e;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fc1e1e;
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #fc1e1e;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #fc1e1e;
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fc1e1e;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fc1e1e;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #fc1e1e;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd5050;
  background-color: #fd5050;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fc1e1e;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fc1e1e;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fc1e1e;
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.125rem 4.5rem;
  font-size: 1rem;
  line-height: 1.1;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #fff;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.btn-primary:hover {
  color: #fff;
  background-color: #339ab5;
  border-color: #3091ab;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 186, 210, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3091ab;
  border-color: #2e89a1;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 186, 210, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fc1e1e;
  border-color: #fc1e1e;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f10303;
  border-color: #e40303;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 64, 64, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #fc1e1e;
  border-color: #fc1e1e;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e40303;
  border-color: #d70303;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 64, 64, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #44aeca;
  border-color: #44aeca;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 174, 202, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #44aeca;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 174, 202, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #fc1e1e;
  border-color: #fc1e1e;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fc1e1e;
  border-color: #fc1e1e;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #fc1e1e;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fc1e1e;
  border-color: #fc1e1e;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}
.btn-link:hover {
  color: #d9d9d9;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.8125rem 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.9rem 4rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: inherit;
  text-decoration: none;
  background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: #fff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.8125rem 4rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.9rem 4rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 3.375rem;
  padding-left: 3.375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 0.875rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.0625;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #000;
  border: 1px solid #44aeca;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.1rem;
  padding-left: 2rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #44aeca;
  background-color: #44aeca;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: -0.2rem;
  left: -2rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: #000;
  border: #44aeca solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: -0.2rem;
  left: -2rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: gray;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: gray;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: gray;
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(-0.2rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #44aeca;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #000;
  transform: translateX(0.25rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: gray;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #fff;
  background-color: #000;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1.715625rem 2.625rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #000 #000 #44aeca;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #000;
  border-color: #000 #000 #44aeca;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 1.35625rem;
  padding-bottom: 1.35625rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 1.715625rem;
  padding-bottom: 1.715625rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 2.625rem;
  padding-left: 2.625rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #000;
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.875rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem 1.875rem;
}

.card-header {
  padding: 1.5rem 1.5rem 1.875rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.card-header:first-child {
  border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.5rem 1.5rem 1.875rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs {
  margin-right: -1.5rem 1.875rem/2;
  margin-bottom: -1.5rem;
  margin-left: -1.5rem 1.875rem/2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1.5rem 1.875rem/2;
  margin-left: -1.5rem 1.875rem/2;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #44aeca;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #3091ab;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 174, 202, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fc1e1e;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #e40303;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 30, 30, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #235a69;
  background-color: #daeff4;
  border-color: #cbe8f0;
}
.alert-primary hr {
  border-top-color: #b7dfea;
}
.alert-primary .alert-link {
  color: #163943;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #831010;
  background-color: #fed2d2;
  border-color: #fec0c0;
}
.alert-danger hr {
  border-top-color: #fea7a7;
}
.alert-danger .alert-link {
  color: #560a0a;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #000;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #44aeca;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xxl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #235a69;
  background-color: #cbe8f0;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #235a69;
  background-color: #b7dfea;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #235a69;
  border-color: #235a69;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #831010;
  background-color: #fec0c0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #831010;
  background-color: #fea7a7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #831010;
  border-color: #831010;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #44aeca !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3091ab !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #fc1e1e !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e40303 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #44aeca !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #fc1e1e !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.mt-6,
.my-6 {
  margin-top: 6rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 6rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 6rem !important;
}

.m-7 {
  margin: 7rem !important;
}

.mt-7,
.my-7 {
  margin-top: 7rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 7rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 7rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 7rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.mt-8,
.my-8 {
  margin-top: 8rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 8rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 8rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 8rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.mt-9,
.my-9 {
  margin-top: 9rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 9rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 9rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 9rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.mt-10,
.my-10 {
  margin-top: 10rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 10rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.pt-6,
.py-6 {
  padding-top: 6rem !important;
}

.pr-6,
.px-6 {
  padding-right: 6rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.pl-6,
.px-6 {
  padding-left: 6rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.pt-7,
.py-7 {
  padding-top: 7rem !important;
}

.pr-7,
.px-7 {
  padding-right: 7rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 7rem !important;
}

.pl-7,
.px-7 {
  padding-left: 7rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.pr-8,
.px-8 {
  padding-right: 8rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}

.pl-8,
.px-8 {
  padding-left: 8rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.pt-9,
.py-9 {
  padding-top: 9rem !important;
}

.pr-9,
.px-9 {
  padding-right: 9rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 9rem !important;
}

.pl-9,
.px-9 {
  padding-left: 9rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.pt-10,
.py-10 {
  padding-top: 10rem !important;
}

.pr-10,
.px-10 {
  padding-right: 10rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10rem !important;
}

.pl-10,
.px-10 {
  padding-left: 10rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -6rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -6rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important;
}

.m-n7 {
  margin: -7rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -7rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important;
}

.m-n8 {
  margin: -8rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -8rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important;
}

.m-n9 {
  margin: -9rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -9rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important;
}

.m-n10 {
  margin: -10rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important;
  }
  .m-sm-7 {
    margin: 7rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important;
  }
  .m-sm-10 {
    margin: 10rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important;
  }
  .p-sm-7 {
    padding: 7rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important;
  }
  .p-sm-10 {
    padding: 10rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -6rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important;
  }
  .m-sm-n7 {
    margin: -7rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important;
  }
  .m-sm-n8 {
    margin: -8rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important;
  }
  .m-sm-n9 {
    margin: -9rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important;
  }
  .m-sm-n10 {
    margin: -10rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important;
  }
  .m-md-7 {
    margin: 7rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important;
  }
  .m-md-10 {
    margin: 10rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important;
  }
  .p-md-7 {
    padding: 7rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important;
  }
  .p-md-10 {
    padding: 10rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -6rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important;
  }
  .m-md-n7 {
    margin: -7rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important;
  }
  .m-md-n8 {
    margin: -8rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important;
  }
  .m-md-n9 {
    margin: -9rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important;
  }
  .m-md-n10 {
    margin: -10rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important;
  }
  .m-lg-7 {
    margin: 7rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important;
  }
  .m-lg-10 {
    margin: 10rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important;
  }
  .p-lg-7 {
    padding: 7rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important;
  }
  .p-lg-10 {
    padding: 10rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -6rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important;
  }
  .m-lg-n7 {
    margin: -7rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important;
  }
  .m-lg-n8 {
    margin: -8rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important;
  }
  .m-lg-n9 {
    margin: -9rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important;
  }
  .m-lg-n10 {
    margin: -10rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 6rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important;
  }
  .m-xl-7 {
    margin: 7rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important;
  }
  .m-xl-10 {
    margin: 10rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 6rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important;
  }
  .p-xl-7 {
    padding: 7rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important;
  }
  .p-xl-10 {
    padding: 10rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -6rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important;
  }
  .m-xl-n7 {
    margin: -7rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important;
  }
  .m-xl-n8 {
    margin: -8rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important;
  }
  .m-xl-n9 {
    margin: -9rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important;
  }
  .m-xl-n10 {
    margin: -10rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .m-xxl-6 {
    margin: 6rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 6rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 6rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 6rem !important;
  }
  .m-xxl-7 {
    margin: 7rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 7rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 7rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 7rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 8rem !important;
  }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 8rem !important;
  }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 8rem !important;
  }
  .m-xxl-9 {
    margin: 9rem !important;
  }
  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 9rem !important;
  }
  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 9rem !important;
  }
  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 9rem !important;
  }
  .m-xxl-10 {
    margin: 10rem !important;
  }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 10rem !important;
  }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 10rem !important;
  }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 10rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .p-xxl-6 {
    padding: 6rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 6rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 6rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 6rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 6rem !important;
  }
  .p-xxl-7 {
    padding: 7rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 7rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 7rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 7rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 7rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 8rem !important;
  }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 8rem !important;
  }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 8rem !important;
  }
  .p-xxl-9 {
    padding: 9rem !important;
  }
  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 9rem !important;
  }
  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 9rem !important;
  }
  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 9rem !important;
  }
  .p-xxl-10 {
    padding: 10rem !important;
  }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 10rem !important;
  }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 10rem !important;
  }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 10rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -6rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -6rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -6rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -6rem !important;
  }
  .m-xxl-n7 {
    margin: -7rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -7rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -7rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -7rem !important;
  }
  .m-xxl-n8 {
    margin: -8rem !important;
  }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -8rem !important;
  }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -8rem !important;
  }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -8rem !important;
  }
  .m-xxl-n9 {
    margin: -9rem !important;
  }
  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -9rem !important;
  }
  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -9rem !important;
  }
  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -9rem !important;
  }
  .m-xxl-n10 {
    margin: -10rem !important;
  }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -10rem !important;
  }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -10rem !important;
  }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -10rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #44aeca !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #2b8097 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #fc1e1e !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #cb0303 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #fff !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
:focus {
  outline: 0 transparent;
}

.alert {
  font-size: 0.875rem;
  font-weight: 300;
  padding-left: 2rem;
}
.alert:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.75rem;
  color: #fff;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 11'%3e%3cpath fill='white' d='M0 11L2 11L2 9L1.74846e-07 9L0 11ZM2.62268e-07 8L2 8L2 0L9.61651e-07 -1.74846e-07L2.62268e-07 8Z'/%3e%3c/svg%3e");
  width: 1.25rem;
  height: 1.25rem;
  background-size: 2px 11px;
}

.alert-primary {
  color: #44aeca;
  background-color: #000;
  border-color: transparent;
}
.alert-primary hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-primary .alert-link {
  color: #3091ab;
}
.alert-primary:before {
  background-color: #44aeca;
}

.alert-secondary {
  color: #6c757d;
  background-color: #000;
  border-color: transparent;
}
.alert-secondary hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-secondary .alert-link {
  color: #545b62;
}
.alert-secondary:before {
  background-color: #6c757d;
}

.alert-success {
  color: #28a745;
  background-color: #000;
  border-color: transparent;
}
.alert-success hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-success .alert-link {
  color: #1e7e34;
}
.alert-success:before {
  background-color: #28a745;
}

.alert-info {
  color: #17a2b8;
  background-color: #000;
  border-color: transparent;
}
.alert-info hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-info .alert-link {
  color: #117a8b;
}
.alert-info:before {
  background-color: #17a2b8;
}

.alert-warning {
  color: #ffc107;
  background-color: #000;
  border-color: transparent;
}
.alert-warning hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-warning .alert-link {
  color: #d39e00;
}
.alert-warning:before {
  background-color: #ffc107;
}

.alert-danger {
  color: #fc1e1e;
  background-color: #000;
  border-color: transparent;
}
.alert-danger hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-danger .alert-link {
  color: #e40303;
}
.alert-danger:before {
  background-color: #fc1e1e;
}

.alert-light {
  color: #f8f9fa;
  background-color: #000;
  border-color: transparent;
}
.alert-light hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-light .alert-link {
  color: #dae0e5;
}
.alert-light:before {
  background-color: #f8f9fa;
}

.alert-dark {
  color: #343a40;
  background-color: #000;
  border-color: transparent;
}
.alert-dark hr {
  border-top-color: rgba(0, 0, 0, 0);
}
.alert-dark .alert-link {
  color: #1d2124;
}
.alert-dark:before {
  background-color: #343a40;
}

@font-face {
  font-family: "Univers";
  src: url(../assets/fonts/Univers-Bold.eot?);
  src: local("Univers Bold"), local("Univers 65 Bold"), url(../assets/fonts/Univers-Bold.eot?#iefix) format("embedded-opentype"), url("../assets/fonts/Univers-Bold.woff") format("woff"), url("../assets/fonts/Univers-Bold.ttf") format("truetype"), url("../assets/fonts/Univers-Bold.svg#Univers") format("svg");
  font-weight: 700;
  font-style: "normal";
}
@font-face {
  font-family: "Univers";
  src: url(../assets/fonts/Univers-Light.eot?);
  src: local("Univers Light"), local("Univers 45 Light"), url(../assets/fonts/Univers-Light.eot?#iefix) format("embedded-opentype"), url("../assets/fonts/Univers-Light.woff") format("woff"), url("../assets/fonts/Univers-Light.ttf") format("truetype"), url("../assets/fonts/Univers-Light.svg#Univers") format("svg");
  font-weight: 300;
  font-style: "normal";
}
@font-face {
  font-family: "Univers";
  src: url(../assets/fonts/Univers-Regular.eot?);
  src: local("Univers Regular"), local("Univers"), local("Univers 55"), url(../assets/fonts/Univers-Regular.eot?#iefix) format("embedded-opentype"), url("../assets/fonts/Univers-Regular.woff") format("woff"), url("../assets/fonts/Univers-Regular.ttf") format("truetype"), url("../assets/fonts/Univers-Regular.svg#Univers") format("svg");
  font-weight: 400;
  font-style: "normal";
}
@font-face {
  font-family: "neue-haas-unica";
  src: local("Neue Haas Unica SemiBold"), url("../assets/fonts/Neue-Haas-Unica-Semibold.woff2") format("woff2"), url("../assets/fonts/Neue-Haas-Unica-Semibold.woff") format("woff"), url("../assets/fonts/Neue-Haas-Unica-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: "normal";
}
@font-face {
  font-family: "neue-haas-unica";
  src: local("Neue Haas Unica Italic"), url("../assets/fonts/Neue-Haas-Unica-Italic.woff2") format("woff2"), url("../assets/fonts/Neue-Haas-Unica-Italic.woff") format("woff"), url("../assets/fonts/Neue-Haas-Unica-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: "italic";
}
@font-face {
  font-family: "neue-haas-unica";
  src: local("Neue Haas Unica Light"), url("../assets/fonts/Neue-Haas-Unica-Light.woff2") format("woff2"), url("../assets/fonts/Neue-Haas-Unica-Light.woff") format("woff"), url("../assets/fonts/Neue-Haas-Unica-Light.otf") format("opentype");
  font-weight: 300;
  font-style: "normal";
}
@font-face {
  font-family: "neue-haas-unica";
  src: local("Neue Haas Unica"), local("Neue Haas Unica Regular"), url("../assets/fonts/Neue-Haas-Unica-Regular.woff2") format("woff2"), url("../assets/fonts/Neue-Haas-Unica-Regular.woff") format("woff"), url("../assets/fonts/Neue-Haas-Unica-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: "normal";
}
.form-control:-webkit-autofill, .form-control:-webkit-autofill:hover, .form-control:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}
.form-control:disabled, .form-control[readonly] {
  opacity: 0.5;
}
.form-control::placeholder {
  font-weight: 300;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}
.icon {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.icon.icon-fw {
  text-align: center;
  width: 1.25em;
}

@media (max-width: 991.98px) {
  .modal .modal-dialog {
    max-width: none;
    margin: 0;
    height: 100vh;
  }
}
@media (max-width: 991.98px) {
  .modal .modal-content {
    height: 100vh;
    overflow: scroll;
  }
}
.modal .close {
  color: #fff;
  text-shadow: none;
  position: relative;
  opacity: 1;
}
.modal .close span {
  visibility: hidden;
}
.modal .close:before {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  width: 20px;
  height: 2px;
  background-color: #fff;
  transform: rotateZ(-45deg);
}
.modal .close:after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  width: 20px;
  height: 2px;
  background-color: #fff;
  transform: rotateZ(45deg);
}
.modal .modal-title {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 2.125rem;
  letter-spacing: 0.014em;
  padding-top: 1rem;
}

.nav-tabs .nav-link {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
}
.nav-tabs .nav-link:hover {
  color: #44aeca;
}

#app-site {
  height: auto;
  min-height: 100vh;
}

.app-main, .app-container {
  width: 100%;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.app-content {
  flex-grow: 1;
}

.app-page-title {
  color: #44aeca;
  font-weight: 300;
  font-size: 2.75rem;
  line-height: 1.2;
  letter-spacing: 0.011rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (max-width: 991.98px) {
  .app-page-title {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767.98px) {
  .app-page-title {
    font-size: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

hr, .hr {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
}

.logo {
  padding: 2rem 0;
}
@media (max-width: 767.98px) {
  .logo {
    padding: 1rem 0;
  }
}
.logo .energizer {
  background: url("../assets/images/energizer.svg") center center transparent no-repeat;
  width: 260.8px;
  height: 65.83px;
  margin: auto;
}
.logo .homepower {
  background: url("../assets/images/homepower.svg") center center transparent no-repeat;
  width: 192.6px;
  height: 17.61px;
  margin: 1.7rem auto 0;
}
.logo.logo-short {
  padding: 0;
}
.logo.logo-short .energizer {
  width: 105px;
  height: 25.48px;
  background-size: contain;
}

.app-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.app-header .navbar .navbar-brand {
  margin-right: 4.5rem;
}
@media (max-width: 1199.98px) {
  .app-header .navbar .navbar-brand {
    margin-right: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .app-header .navbar .navbar-brand {
    margin-right: 1rem;
  }
}
@media (max-width: 767.98px) {
  .app-header .navbar .navbar-brand {
    margin-right: 0;
  }
}
.app-header .navbar .nav-link {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.175;
  text-transform: uppercase;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .app-header .navbar .nav-link {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-header .navbar .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .app-header .navbar .nav-link {
    padding: 1.25rem 1rem 1.25rem 3rem;
  }
}
.app-header .navbar .nav-link > .icon {
  position: absolute;
  left: 0.75rem;
  font-size: 1.125rem;
  top: calc(50% - 1.125rem/2);
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-header .navbar .nav-link > .icon {
    position: static;
  }
}
.app-header .navbar .nav-link > .icon.icon-report {
  font-size: 0.875rem;
  top: calc(50% - 0.875rem/2);
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-header .navbar .nav-link > .nav-text {
    display: none;
  }
}
.app-header .navbar .nav-link.active > .icon {
  color: #44aeca;
}
@media (min-width: 992px) {
  .app-header .navbar .nav-link.active:after, .app-header .navbar .nav-link:hover:after, .app-header .navbar .nav-link:focus:after {
    content: "";
    border-bottom: 1px solid #44aeca;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
  }
}
.app-header .dropdown .nav-link-dropdown:before {
  content: "";
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='white' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") no-repeat center center;
  position: absolute;
  right: 0.75rem;
  top: calc(50% - 0.5625rem/2);
  width: 0.5625rem;
  height: 0.5625rem;
  transition: transform 0.5s ease-in 0s;
}
.app-header .dropdown.show .nav-link-dropdown:before {
  transform: rotateX(180deg);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='%2344aeca' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") !important;
}
.app-header .dropdown .dropdown-item {
  position: relative;
  font-weight: 300;
}
.app-header .dropdown .dropdown-item > .icon {
  position: absolute;
  left: 1.5rem;
  font-size: 1.125rem;
  top: calc(50% - 1.125rem/2);
}
.app-header .dropdown .dropdown-item.active {
  font-weight: 600;
}
.app-header .dropdown .dropdown-item.active:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0.25rem;
  height: 100%;
  background-color: #44aeca;
  content: "";
}
.app-header .dropdown .dropdown-item.active > .icon {
  color: #44aeca;
}
.app-header .dropdown .dropdown-item:hover, .app-header .dropdown .dropdown-item:focus {
  background-color: rgba(68, 174, 202, 0.5);
}
.app-header .dropdown .dropdown-item:hover > .icon, .app-header .dropdown .dropdown-item:focus > .icon {
  color: #000;
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item .nav-link {
    font-weight: 400;
    text-transform: none;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item .nav-link > .icon {
    font-size: 1.5rem;
    top: calc(50% - 1.5rem/2);
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item .dropdown.show {
    background-color: #fff;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item .dropdown.show .nav-link {
    color: #000;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item .dropdown.show .nav-link-dropdown:before {
    top: calc(50% - 0.815rem/2);
    width: 0.815rem;
    height: 0.815rem;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.device-select .nav-link {
    width: 240px;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .app-header .menu-user .nav-item.device-select .nav-link {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .app-header .menu-user .nav-item.device-select .nav-link .device-serial {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(240px - 5.25rem);
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .app-header .menu-user .nav-item.device-select .nav-link .device-serial {
    display: none;
  }
}
.app-header .menu-user .nav-item.device-select .nav-link .placeholder {
  display: none;
}
.app-header .menu-user .nav-item.device-select.show {
  background-color: #e3f3f7;
  border: 0 none;
}
.app-header .menu-user .nav-item.device-select.show .nav-link {
  color: #000;
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.device-select.show .nav-link {
    font-size: 0.875rem;
    padding-top: 1.6421875rem;
    padding-bottom: 1.6421875rem;
    font-weight: 400;
  }
}
.app-header .menu-user .nav-item.device-select.show .nav-link .device-serial {
  display: none;
}
.app-header .menu-user .nav-item.device-select.show .nav-link .placeholder {
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .app-header .menu-user .nav-item.device-select.show .nav-link .placeholder {
    display: none;
  }
}
.app-header .menu-user .nav-item.device-select.show .dropdown-menu {
  background-color: #e3f3f7;
  padding-bottom: 0;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item {
  background-color: #e3f3f7;
  position: relative;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.device-select-item {
  font-size: 0.875rem;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.device-select-item:before {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  border: 1px solid #44aeca;
  background-color: #fff;
  left: 1.8125rem;
  top: calc(50% - 0.6875rem);
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.device-select-item.active:before {
  background-color: #44aeca;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.device-select-item.active:after {
  content: "";
  position: absolute;
  left: 1.8125rem;
  top: calc(50% - 0.6875rem);
  width: 1.375rem;
  height: 1.375rem;
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23fff' d='M54.264 2.777l-33.517 41.899-13.81-9.214-6.851 10.276 23.241 15.486 40.584-50.727-9.649-7.719z'/%3e%3c/svg%3e") center center no-repeat transparent;
  background-size: 0.5625rem;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.link-battery {
  color: #fff;
  background-color: #44aeca;
  margin-top: 1rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  text-transform: uppercase;
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.link-battery .icon {
  left: 1.6rem;
  font-size: 1.5rem;
  top: calc(50% - 1.5rem/2);
}
.app-header .menu-user .nav-item.device-select.show .dropdown-item.link-battery:hover .icon, .app-header .menu-user .nav-item.device-select.show .dropdown-item.link-battery:focus .icon, .app-header .menu-user .nav-item.device-select.show .dropdown-item.link-battery:active .icon {
  color: #fff;
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.device-select .nav-link-dropdown:before {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='%23fff' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e");
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications.show .nav-link {
    color: #000;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications.show {
    background-color: #fff;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications.show.unread {
    background-color: #E3F3F7;
  }
}
.app-header .menu-user .nav-item.notifications .unread-count {
  font-size: 0.5rem;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
  background-color: #fc1e1e;
  padding: 1px 7px;
  position: absolute;
  border-radius: 0.5rem;
  top: 1rem;
  right: 1rem;
}
@media (max-width: 767.98px) {
  .app-header .menu-user .nav-item.notifications .unread-count {
    right: auto;
    left: 1.5rem;
  }
}
.app-header .menu-user .nav-item.profile .dropdown-menu {
  padding-bottom: 0;
}
.app-header .menu-user .nav-item.profile .nav-link .profile-close {
  display: none;
}
.app-header .menu-user .nav-item.profile.show .nav-link .profile-open {
  display: none;
}
.app-header .menu-user .nav-item.profile.show .nav-link .profile-close {
  display: block;
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.profile.show .nav-link {
    color: #000;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.profile.show {
    background-color: #fff;
  }
}
.app-header .menu-user .nav-item.profile .link-battery {
  color: #44aeca;
  line-height: 2rem;
  font-size: 0.875rem;
}
.app-header .menu-user .nav-item.profile .link-battery:hover, .app-header .menu-user .nav-item.profile .link-battery:focus, .app-header .menu-user .nav-item.profile .link-battery:active {
  color: #fff;
  background-color: #44aeca;
}
.app-header .menu-user .nav-item.profile .link-battery:hover .icon, .app-header .menu-user .nav-item.profile .link-battery:focus .icon, .app-header .menu-user .nav-item.profile .link-battery:active .icon {
  color: #fff;
}
.app-header .menu-user .nav-item.profile .dropdown-header {
  font-size: 1.0625rem;
  line-height: 1.25;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 0.5rem;
}
.app-header .menu-user .nav-item.profile .dropdown-header .icon {
  position: absolute;
  font-size: 1.875rem;
  color: #44aeca;
  left: 1.1rem;
  top: calc(50% - 1.875rem/2);
}
.app-header .menu-user .nav-item.profile .dropdown-header .logout .icon {
  font-size: 1.375rem;
  color: #44aeca;
  right: 1.1rem;
  left: auto;
  top: calc(50% - 1.375rem/2);
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications .nav-link, .app-header .menu-user .nav-item.profile .nav-link {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications .nav-link .icon, .app-header .menu-user .nav-item.profile .nav-link .icon {
    left: calc(50% - 1.5rem/2 - 2px);
    top: calc(50% - 1.5rem/2);
  }
}
@media (min-width: 768px) {
  .app-header .menu-user .nav-item.notifications .nav-link .nav-text, .app-header .menu-user .nav-item.profile .nav-link .nav-text {
    display: none;
  }
}

.app-footer {
  font-size: 0.75rem;
  position: relative;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 162%;
  letter-spacing: 0.02em;
}
@media (max-width: 991.98px) {
  .app-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.app-footer:before {
  content: "";
  background: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
@media (max-width: 991.98px) {
  .app-footer {
    margin-top: 2rem;
  }
}
.app-footer .copyright {
  margin-bottom: 1rem;
}
.app-footer .disclaimer {
  opacity: 0.5;
  font-size: 0.625rem;
  max-width: 1000px;
}
@media (max-width: 1199.98px) {
  .app-footer .disclaimer {
    max-width: 800px;
  }
}

.ios-mobile-view-height #app-site > .modal .modal-title {
  min-height: 6.25rem;
}

.card .card-title {
  color: #fff;
  font-weight: 300;
  font-size: 2.125rem;
  line-height: 0.85em;
  letter-spacing: 0.015em;
}
@media (max-width: 991.98px) {
  .card .card-title {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .card .card-body {
    padding: 1rem;
  }
}

@media (max-width: 991.98px) {
  .carousel {
    margin: 0 -15px;
    padding: 0 15px;
    overflow-x: auto;
  }
}
.carousel .carousel-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .carousel .carousel-container {
    padding-right: 15px;
  }
}

.creatable-select__control {
  width: 100%;
  height: calc(1.0625em + 1.75rem + 2px);
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.0625;
  color: #fff !important;
  background-color: #000 !important;
  background-clip: padding-box;
  border: 1px solid #44aeca !important;
  border-radius: 0 !important;
}
.creatable-select__single-value {
  color: #fff !important;
}
.creatable-select__value-container input {
  color: #fff !important;
  font-weight: 600;
  opacity: 1 !important;
  height: 100%;
}
.creatable-select__indicator-separator {
  display: none;
}
.creatable-select__indicator {
  color: #44aeca !important;
}
.creatable-select__menu {
  background-color: #000 !important;
  border: 1px solid #fff !important;
  border-radius: 0 !important;
  z-index: 200;
}
.creatable-select__menu-list {
  background-color: #000 !important;
}
.creatable-select__option {
  background-color: #000 !important;
}
.creatable-select__option--is-focused {
  background-color: rgba(68, 174, 202, 0.5) !important;
}
.creatable-select__option:hover {
  background-color: rgba(68, 174, 202, 0.5) !important;
}

.device-offline-modal__footer {
  justify-content: center;
}
.device-offline-modal__description {
  font-weight: 300;
}
.device-offline-modal__image {
  background: url("../assets/images/device-offline.svg") center center transparent no-repeat;
  height: 180px;
  margin-top: 2rem;
}
.device-offline-modal__image_vpp {
  background-image: url("../assets/images/device-vpp.svg");
}
.device-offline-modal__refresh-screen-btn {
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .device-offline-modal__refresh-screen-btn {
    display: block;
    width: 100%;
  }
}

.dateslineselect__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .dateslineselect__container_date {
    width: 608px;
  }
  .dateslineselect__container_month {
    width: 1136px;
  }
  .dateslineselect__container_year {
    width: 432px;
  }
}
.dateslineselect__block {
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  padding: 1.75rem 1rem;
}
.dateslineselect__block_date {
  width: 150px;
}
.dateslineselect__block_month {
  width: 96px;
}
.dateslineselect__block_year {
  width: 96px;
}
@media (max-width: 991.98px) {
  .dateslineselect__block {
    width: 80px;
  }
}
.dateslineselect__block_date {
  font-weight: 300;
  padding: 0;
}
.dateslineselect__block_year {
  font-weight: 300;
  margin-right: 0.5rem;
  flex-grow: 1;
}
.dateslineselect__block_year:last-of-type {
  margin-right: 0;
}
.dateslineselect__block:hover {
  border-color: #fff;
}
.dateslineselect__block_active {
  border: 0 none;
  background: #44aeca;
  cursor: default;
}
.dateslineselect__block_disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border-color: rgba(255, 255, 255, 0.4);
}
.dateslineselect__block_disabled:hover {
  border-color: white;
}
.dateslineselect__block_datepicker {
  color: #44aeca;
  border-color: #44aeca;
  padding: 0;
}
@media (max-width: 1199.98px) {
  .dateslineselect__block_datepicker {
    padding: 0 0.5rem;
  }
}
.dateslineselect__block_datepicker .react-datepicker-wrapper,
.dateslineselect__block_datepicker .react-datepicker__input-container,
.dateslineselect__block_datepicker .dateslineselect__value {
  width: 100%;
  height: 100%;
}
.dateslineselect__block_datepicker .dateslineselect__value {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateslineselect__block_static {
  cursor: default;
}
.dateslineselect__block_static:hover {
  border-color: inherit;
}
.dateslineselect__block + .dateslineselect__block {
  margin-left: 8px;
}
.dateslineselect .dropdown + .dateslineselect__block {
  margin-left: 8px;
}
.dateslineselect__value_week {
  font-size: 1.125rem;
  line-height: 2.22em;
  letter-spacing: 0.03em;
}
@media (max-width: 767.98px) {
  .dateslineselect__value_week {
    font-size: 1rem;
  }
}
.dateslineselect__value_day {
  font-weight: 600;
  font-size: 2.05rem;
  line-height: 0.825em;
  letter-spacing: 0.015em;
}
@media (max-width: 767.98px) {
  .dateslineselect__value_day {
    font-size: 1.5rem;
  }
}
.dateslineselect__value_month {
  font-size: 0.8125rem;
  line-height: 3.08em;
  letter-spacing: 0.037em;
}
@media (max-width: 767.98px) {
  .dateslineselect__value_month {
    line-height: 2.22em;
  }
}
.dateslineselect__arrow-down {
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='%2344aeca' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") no-repeat center center;
  display: inline-block;
  margin-left: 0.5rem;
}
.dateslineselect__dropdown-menu {
  background-color: transparent;
  padding: 0;
  max-width: 320px;
}
@media (max-width: 991.98px) {
  .dateslineselect__dropdown-menu {
    top: 70px !important;
  }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -12px;
  position: absolute;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  box-sizing: content-box;
  position: absolute;
  border: 12px solid transparent;
  height: 0;
  width: 1px;
}
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  content: "";
  z-index: -1;
  border-width: 12px;
  left: -12px;
  border-bottom-color: #fff;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -12px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #fff;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #fff;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -12px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #fff;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  margin-top: 14px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  margin-bottom: 14px;
}
.react-datepicker-popper[data-placement^=right] {
  margin-left: 12px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  margin-right: 12px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-top-left-radius: 0;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 1.18rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__navigation--previous:hover {
  border-right-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: rgba(85, 79, 96, 0.2);
  cursor: default;
}
.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}
.react-datepicker__navigation--next:hover {
  border-left-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: rgba(85, 79, 96, 0.2);
  cursor: default;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__navigation--years-previous:hover {
  border-top-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: rgba(35, 33, 40, 0.2);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0 1rem 1rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0 1rem 1rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #fff;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 2rem / 2);
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #fff;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #44aeca;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #44aeca;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: rgba(60, 56, 68, 0.2);
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.4rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0;
  background-color: #fff;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.4rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0;
  background-color: #44aeca;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #36a2bf;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: rgba(60, 56, 68, 0.2);
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
  background-color: #fff;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0;
  background-color: #44aeca;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #36a2bf;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
  background-color: #44aeca;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #36a2bf;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  background-color: #6cbfd5;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #36a2bf;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(68, 174, 202, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #fff;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #44aeca;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #fff;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: rgba(60, 56, 68, 0.2);
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  border: 1px solid #fff;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #44aeca;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #fff;
  border-top: 1px solid #fff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.8rem;
}
.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: rgba(85, 79, 96, 0.2);
  cursor: default;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: rgba(60, 56, 68, 0.2);
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: rgba(35, 33, 40, 0.2);
}
.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: rgba(85, 79, 96, 0.2);
  cursor: default;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.033em;
}

.react-datepicker__navigation--previous {
  border-right-color: #44aeca;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #3091ab;
}

@media (max-width: 767.98px) {
  .react-datepicker__month {
    margin: 0 0.4rem 0.4rem;
  }
}

@media (max-width: 767.98px) {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.2rem;
  }
}

.react-datepicker__navigation {
  top: 25px;
}

.react-datepicker__navigation--next {
  border-left-color: #44aeca;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #3091ab;
}

.react-datepicker__quarter,
.react-datepicker__year-container-text {
  font-size: 1.0625rem;
  font-weight: 600;
  padding: 1rem 0.5rem;
  margin: 0.25rem;
  border: 1px solid #44aeca;
  color: #44aeca;
}
.react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range,
.react-datepicker__year-container-text--selected,
.react-datepicker__year-container-text--in-selecting-range,
.react-datepicker__year-container-text--in-range {
  color: #e9ecef;
}

.react-datepicker__day {
  font-weight: 400;
}

.react-datepicker__header {
  padding-top: 1.5rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  color: #000;
}

.react-datepicker__year-container {
  margin: 1rem;
}

.react-datepicker__year-container-text {
  box-sizing: content-box;
}
.react-datepicker__year-container-text--disabled {
  border-color: rgba(60, 56, 68, 0.2);
  color: rgba(60, 56, 68, 0.2);
}

.dateselect {
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 0.00875em;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .dateselect {
    font-size: 1.5rem;
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .dateselect {
    font-size: 1.1rem;
  }
}
.dateselect .dateselect-calendar {
  color: #44aeca;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0 none;
}
.dateselect .dateselect-calendar:focus {
  outline: 0;
}
.dateselect .dateselect-calendar .icon-calendar {
  color: #44aeca;
}
.dateselect .arrow-down {
  width: 2rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='%2344aeca' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") no-repeat center center;
  display: inline-block;
}
.dateselect .selected-date {
  margin: 0 2rem;
}
@media (max-width: 767.98px) {
  .dateselect .selected-date {
    margin: 0 1rem;
    flex-grow: 1;
    text-align: center;
  }
}
.dateselect .date-prev-btn, .dateselect .date-next-btn {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-size: 50%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 2.8125rem;
  height: 2.8125rem;
}
.dateselect .date-prev-btn:hover, .dateselect .date-next-btn:hover {
  border-color: #fff;
}
.dateselect .date-prev-btn_disabled, .dateselect .date-next-btn_disabled {
  cursor: default;
  opacity: 0.5;
  border-color: rgba(255, 255, 255, 0.4);
}
.dateselect .date-prev-btn_disabled:hover, .dateselect .date-next-btn_disabled:hover {
  border-color: rgba(255, 255, 255, 0.4);
}
.dateselect .date-prev-btn {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='2.7826' stroke='%23fff' d='M22.286 30.667l-12.571-14.667 12.571-14.667'/%3e%3c/svg%3e");
}
.dateselect .date-next-btn {
  margin-left: 0.5rem;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='2.7826' stroke='%23fff' d='M9.714 30.667l12.571-14.667-12.571-14.667'/%3e%3c/svg%3e");
}

.device-info .device-image {
  background-position: center center;
  background-color: transparent;
  width: 315px;
  height: 342px;
  margin: auto;
}
@media (max-width: 767.98px) {
  .device-info .device-image {
    max-width: 100%;
    height: 250px;
  }
}
.device-info .device-image.b-1 {
  background-image: url("../assets/images/devices/energizer-hp6-1-off.png");
}
.device-info .device-image.b-1.on {
  background-image: url("../assets/images/devices/energizer-hp6-1-on.png");
}
.device-info .device-image.b-2 {
  background-image: url("../assets/images/devices/energizer-hp6-2-off.png");
}
.device-info .device-image.b-2.on {
  background-image: url("../assets/images/devices/energizer-hp6-2-on.png");
}
.device-info .device-image.b-3 {
  background-image: url("../assets/images/devices/energizer-hp6-3-off.png");
}
.device-info .device-image.b-3.on {
  background-image: url("../assets/images/devices/energizer-hp6-3-on.png");
}
.device-info .device-image.b-4 {
  background-image: url("../assets/images/devices/energizer-hp6-4-off.png");
}
.device-info .device-image.b-4.on {
  background-image: url("../assets/images/devices/energizer-hp6-4-on.png");
}
.device-info .device-soc-value {
  border: 1px solid #44aeca;
  text-align: center;
  padding: 1rem 0;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.6;
  height: 100%;
  position: relative;
}
.device-info .device-soc-value .device-soc-fill {
  position: absolute;
  left: 2px;
  bottom: 2px;
  background: #0093b9;
  background: linear-gradient(0deg, #000, #0093b9 100%);
  width: calc(100% - 4px);
}
.device-info .device-soc-value .device-soc-percent {
  position: relative;
}
.device-info .info-block {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.125rem;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.25rem;
}
@media (max-width: 1439.98px) {
  .device-info .info-block {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) and (max-width: 1439.98px) {
  .device-info .info-block {
    padding: 1.25rem 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .device-info .info-block {
    height: auto;
  }
}
.device-info .info-block .info-block-title {
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 767.98px) {
  .device-info .info-block .info-block-title {
    margin-bottom: 1rem;
  }
}
.device-info .info-block .info-block-value {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.device-info .info-block .info-block-value_small {
  font-size: 80%;
}
.device-info .info-block .info-block-value_extra-small {
  font-size: 70%;
}
.device-info .info-block .info-block-value-badge {
  background-color: #6E757B;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 2;
  display: inline-block;
  text-align: center;
  min-width: 1.5rem;
  margin-right: 0.5rem;
}
.device-info .info-block .info-block-small {
  font-size: 0.75rem;
}
.device-info .info-block .device-net-status {
  position: relative;
  padding-left: 2.5rem;
  line-height: 1.75rem;
}
.device-info .info-block .device-net-status:after {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 0.875rem);
  border-radius: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  border-width: 3px;
  border-style: solid;
}
.device-info .info-block .device-net-status.online:after {
  border-color: #28a745;
}
.device-info .info-block .device-net-status.offline:after {
  border-color: #fc1e1e;
}
.device-info .info-block .device-state {
  padding: 0.5rem;
  border: 1px solid #44aeca;
  display: inline-block;
}

.display-width-alert {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(252, 30, 30, 0.75);
  padding: 1rem;
  text-align: center;
  font-size: 1.25rem;
}
@media (min-width: 768px) and (max-width: 1439.98px) {
  .display-width-alert {
    display: block;
  }
}

.energy-flow {
  width: 70%;
  margin: auto;
  position: relative;
  color: #78797b;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991.98px) {
  .energy-flow {
    width: 100%;
    max-width: 450px;
  }
}
@media (max-width: 767.98px) {
  .energy-flow {
    max-width: 330px;
  }
}
.energy-flow_offline {
  filter: grayscale(1);
}
@media (max-width: 991.98px) {
  .energy-flow_offline .energy-flow__totalloads {
    display: none;
  }
}
.energy-flow__offline {
  text-align: center;
  align-self: center;
  font-size: 2.125rem;
  line-height: 1.18;
  width: 100%;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
@media (max-width: 991.98px) {
  .energy-flow__offline {
    background-color: #000;
    font-size: 0.75rem;
    font-weight: 400;
    left: 0;
    padding: 0.25rem 0;
    z-index: 10;
    text-align: left;
    justify-content: flex-start;
    order: 1;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .energy-flow__offline-note {
    font-size: 1rem;
    line-height: 1.37;
    margin-top: 0.5rem;
  }
}
.energy-flow__offline-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-right: 0.5em;
}
@media (min-width: 992px) {
  .energy-flow__offline-icon {
    display: none;
  }
}
.energy-flow__report {
  position: relative;
  width: 100%;
  height: 350px;
}
@media (min-width: 992px) {
  .energy-flow__report {
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__report {
    margin: 2rem 0 2rem;
    height: 600px;
  }
}
.energy-flow__object {
  border-radius: 50%;
  border: 2px solid #6E757B;
  position: absolute;
  color: #6E757B;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 75px;
  height: 75px;
}
@media (min-width: 992px) {
  .energy-flow__object {
    width: 100px;
    height: 100px;
  }
}
.energy-flow__object_active {
  color: #fff;
  border-color: #fff;
  animation: animatedObjectPulse 2s linear infinite;
}
.energy-flow__object_active .energy-flow__object-icon {
  animation: animatedObjectIconPulse 2s linear infinite;
}
@media (min-width: 992px) {
  .energy-flow__object_sun {
    top: 10%;
    left: 0;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_sun {
    top: 0;
    left: calc(50% - 37.5px);
  }
}
@media (min-width: 992px) {
  .energy-flow__object_sun .energy-flow__object-value {
    text-align: right;
    left: calc(-100px - 1rem);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_sun .energy-flow__object-value {
    left: 50%;
    margin-left: -37.5px;
  }
}
@media (min-width: 992px) {
  .energy-flow__object_backuploads {
    bottom: 10%;
    left: 0;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_backuploads {
    display: none;
  }
}
@media (min-width: 992px) {
  .energy-flow__object_backuploads .energy-flow__object-value {
    text-align: right;
    left: calc(-100px - 1rem);
  }
}
@media (min-width: 992px) {
  .energy-flow__object_grid {
    top: 10%;
    right: 0;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_grid {
    bottom: 2em;
    left: 0;
  }
}
@media (min-width: 992px) {
  .energy-flow__object_grid .energy-flow__object-value {
    text-align: left;
    right: calc(-100px - 1rem);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_grid .energy-flow__object-value {
    top: auto;
    bottom: -2.75em;
  }
}
@media (min-width: 992px) {
  .energy-flow__object_home {
    right: 0;
    bottom: 10%;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_home {
    bottom: 2em;
    right: 0;
  }
}
@media (min-width: 992px) {
  .energy-flow__object_home .energy-flow__object-value {
    text-align: left;
    right: calc(-100px - 1rem);
    top: calc(50% - 0.5em);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object_home .energy-flow__object-value {
    top: auto;
    bottom: -2.75em;
  }
}
.energy-flow__object-icon {
  font-size: 2.75rem;
  position: relative;
  align-self: center;
}
.energy-flow__object-direction {
  position: absolute;
  font-size: 1.0625rem;
  line-height: 1.18;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  bottom: -2em;
}
@media (max-width: 991.98px) {
  .energy-flow__object-direction {
    font-size: 0.75rem;
  }
}
.energy-flow__object-value {
  position: absolute;
  font-size: 1.5rem;
  line-height: 1.18;
  white-space: nowrap;
  width: 100%;
}
@media (min-width: 992px) {
  .energy-flow__object-value {
    top: calc(50% - 0.5em);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__object-value {
    top: -1.5em;
    font-weight: 300;
    font-size: 1.25rem;
  }
}
.energy-flow__object-value-unit {
  margin-left: 0.25em;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .energy-flow__object-value-unit {
    font-size: 1rem;
  }
}
.energy-flow__battery {
  left: calc(50% - 56px);
  top: calc(50% - 153.5px);
  width: 112px;
  height: 307px;
  position: absolute;
  background-size: contain;
  background: url("../assets/images/energy-flow/battery.svg") no-repeat center center transparent;
}
.energy-flow__battery_active {
  color: #fff;
}
@media (max-width: 991.98px) {
  .energy-flow__battery_active .energy-flow__battery-value {
    color: #44aeca;
  }
  .energy-flow__battery_active .energy-flow__battery-soc {
    color: #44aeca;
  }
  .energy-flow__battery_active .energy-flow__battery-soc-bar {
    border-color: #44aeca;
  }
  .energy-flow__battery_active .energy-flow__battery-soc-bar-fill {
    background-color: #44aeca;
  }
}
@media (min-width: 992px) {
  .energy-flow__battery_active:after {
    content: "";
    background: url("../assets/images/energy-flow/battery-active.svg") no-repeat center center transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 1s linear infinite animatedBatteryPulse;
    background-position: -32px -7px;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__battery {
    left: calc(50% - 47.5px);
    top: calc(50% - 136px);
    width: 95px;
    height: 272px;
    background: url("../assets/images/energy-flow/battery-mobile.svg") no-repeat center transparent;
  }
}
.energy-flow__battery-value {
  position: absolute;
  font-size: 1.0625rem;
  line-height: 1.18;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  top: 20%;
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .energy-flow__battery-value {
    font-weight: 300;
    font-size: 1.25rem;
    top: calc(50% - 0.5em);
    right: calc(-95px);
  }
}
.energy-flow__battery-soc {
  position: absolute;
  font-size: 1.5rem;
  line-height: 1.1;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  bottom: 20%;
}
@media (max-width: 991.98px) {
  .energy-flow__battery-soc {
    bottom: auto;
    font-weight: 300;
    font-size: 1.25rem;
    top: calc(50% - 0.5em);
    left: calc(-95px - 1em);
  }
}
.energy-flow__battery-soc-bar {
  border: 1px solid #6E757B;
  height: 100%;
  position: absolute;
  left: -2rem;
  width: 14px;
}
@media (min-width: 992px) {
  .energy-flow__battery-soc-bar {
    display: none;
  }
}
.energy-flow__battery-soc-bar-fill {
  position: absolute;
  left: 2px;
  bottom: 2px;
  background-color: #6E757B;
  width: calc(100% - 4px);
  z-index: -1;
}
.energy-flow__battery-direction {
  position: absolute;
  font-size: 1.0625rem;
  line-height: 1.18;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  bottom: -2em;
}
@media (max-width: 991.98px) {
  .energy-flow__battery-direction {
    font-size: 0.75rem;
  }
}
.energy-flow__stream {
  position: absolute;
  height: 7.5px;
  width: 290px;
  background: url("../assets/images/energy-flow/stream.svg") 0 0 repeat-x transparent;
  background-size: contain;
}
@media (max-width: 1439.98px) {
  .energy-flow__stream {
    width: 210px;
  }
}
@media (max-width: 1199.98px) {
  .energy-flow__stream {
    width: 150px;
  }
}
@media (max-width: 991.98px) {
  .energy-flow__stream {
    width: 5px;
    height: 50px;
    background-repeat: repeat-y;
  }
}
.energy-flow__stream_active {
  background-image: url("../assets/images/energy-flow/stream-active.svg");
  animation: animatedBackground 10s linear infinite;
}
@media (max-width: 991.98px) {
  .energy-flow__stream_active {
    animation: animatedMobileBackground 3s linear infinite;
  }
}
.energy-flow__stream_reverse {
  animation: animatedBackgroundReverse 10s linear infinite;
}
@media (max-width: 991.98px) {
  .energy-flow__stream_reverse {
    animation: animatedMobileBackgroundReverse 3s linear infinite;
  }
}
@media (min-width: 992px) {
  .energy-flow__stream_sun {
    top: calc(10% + 50px - 3.75px);
    left: calc(100px + 1em);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__stream_sun {
    top: calc(75px + 1.5rem);
    left: calc(50% - 2.5px);
  }
}
@media (min-width: 992px) {
  .energy-flow__stream_backuploads {
    left: calc(100px + 1em);
    bottom: calc(10% + 50px - 3.75px);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__stream_backuploads {
    display: none;
  }
}
@media (min-width: 992px) {
  .energy-flow__stream_grid {
    top: calc(10% + 50px - 3.75px);
    right: calc(100px + 1em);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__stream_grid {
    bottom: calc(75px + 1.5rem);
    transform: rotateZ(43deg);
    left: 30%;
  }
}
@media (min-width: 992px) {
  .energy-flow__stream_home {
    right: calc(100px + 1em);
    bottom: calc(10% + 50px - 3.75px);
  }
}
@media (max-width: 991.98px) {
  .energy-flow__stream_home {
    bottom: calc(75px + 1.5rem);
    transform: rotateZ(-43deg);
    right: 30%;
  }
}
.energy-flow__totalloads {
  font-size: 1rem;
  line-height: 1.2;
  padding: 1rem 0;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media (min-width: 992px) {
  .energy-flow__totalloads {
    display: none;
  }
}
.energy-flow__gridsideloads {
  font-size: 0.75rem;
  position: relative;
  align-self: center;
  text-align: right;
  color: #6E757B;
}
.energy-flow__backuploads {
  font-size: 0.75rem;
  position: relative;
  align-self: center;
  text-align: left;
  color: #6E757B;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes animatedBackgroundReverse {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -100% 0;
  }
}
@keyframes animatedMobileBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 100%;
  }
}
@keyframes animatedMobileBackgroundReverse {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -100%;
  }
}
@keyframes animatedObjectPulse {
  from {
    border-color: #fff;
  }
  35% {
    border-color: #44aeca;
  }
  65% {
    border-color: #44aeca;
  }
  to {
    border-color: #fff;
  }
}
@keyframes animatedObjectIconPulse {
  from {
    color: #fff;
  }
  35% {
    color: #44aeca;
  }
  65% {
    color: #44aeca;
  }
  to {
    color: #fff;
  }
}
@keyframes animatedBatteryPulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav-tabs .nav-link {
  padding: 1rem 2rem;
}

.progress {
  border: 1px solid #616161;
}

.report .report-block {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #44aeca;
  padding: 1.875rem;
  height: 100%;
}
@media (max-width: 767.98px) {
  .report .report-block {
    padding: 1rem;
  }
}
.report .report-block.report-block-lg {
  padding: 3rem 1.875rem;
}
@media (max-width: 767.98px) {
  .report .report-block.report-block-lg {
    padding: 1rem;
  }
}
.report .report-block.report-block-lg .report-block-title {
  font-weight: 300;
  font-size: 2.125rem;
  padding-top: 2rem;
}
@media (max-width: 767.98px) {
  .report .report-block.report-block-lg .report-block-title {
    font-size: 1.75rem;
    padding-top: 1rem;
  }
}
.report .report-block.report-block-lg .report-block-icon {
  font-size: 66px;
  position: relative;
  right: auto;
  top: auto;
}
@media (max-width: 767.98px) {
  .report .report-block.report-block-lg .report-block-icon {
    font-size: 2rem;
  }
}
.report .report-block.report-block-lg .report-block-description {
  margin-top: 2rem;
}
@media (max-width: 767.98px) {
  .report .report-block.report-block-lg .report-block-description {
    margin-top: 1rem;
  }
}
.report .report-block.report-block-lg .report-block-chart {
  position: relative;
  right: auto;
  bottom: auto;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}
.report .report-block.report-block-lg .report-block-value {
  font-size: 4.625rem;
  padding-top: 2rem;
  flex-grow: 0;
}
@media (max-width: 767.98px) {
  .report .report-block.report-block-lg .report-block-value {
    font-size: 2rem;
  }
}
.report .report-block-title {
  font-size: 1.5rem;
  line-height: 1.17;
  letter-spacing: 0.02em;
}
@media (max-width: 767.98px) {
  .report .report-block-title {
    font-size: 1.1rem;
  }
}
.report .report-block-description {
  font-size: 0.6785rem;
  line-height: 1.62;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  height: 3rem;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .report .report-block-description {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 3.5rem;
    height: auto;
    overflow: inherit;
  }
}
.report .report-block-value {
  font-weight: 600;
  font-size: 2.75rem;
  letter-spacing: 0.01em;
  color: #44aeca;
  margin-bottom: -0.25em;
  flex-grow: 1;
  display: flex;
  align-items: baseline;
}
@media (max-width: 767.98px) {
  .report .report-block-value {
    font-size: 1.5rem;
  }
}
.report .report-block-value-unit {
  font-weight: 300;
  font-size: 1.375rem;
  letter-spacing: 0.02em;
  color: #44aeca;
  display: inline-block;
  margin-left: 0.75rem;
}
@media (max-width: 767.98px) {
  .report .report-block-value-unit {
    font-size: 0.875rem;
  }
}
.report .report-block-icon {
  font-size: 60px;
  position: absolute;
  right: 1.875rem;
  top: 1.875rem;
}
@media (max-width: 767.98px) {
  .report .report-block-icon {
    font-size: 2rem;
    right: 1rem;
    top: 1rem;
  }
}
.report .report-block-chart {
  position: absolute;
  right: 1.875rem;
  bottom: 1.875rem;
}
@media (max-width: 767.98px) {
  .report .report-block-chart {
    right: 1rem;
    bottom: 1rem;
  }
}
.report .report-block-dot {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.875rem;
  background-color: #44aeca;
  border: 2px solid #44aeca;
}
@media (max-width: 767.98px) {
  .report .report-block-dot {
    display: none;
  }
}
.report .report-block-dot.xl {
  border: 6px solid #44aeca;
  background-color: #000;
}
.report .report-block-dot:after {
  content: "";
  background-color: #44aeca;
  position: absolute;
  z-index: -1;
}
.report .report-block-dot.top {
  left: calc(50% - 0.4375rem);
  top: -0.4375rem;
}
.report .report-block-dot.top:after {
  height: 0.75rem;
  width: 1px;
  bottom: 0.4375rem;
  left: calc(50% - 0.5px);
}
.report .report-block-dot.bottom {
  left: calc(50% - 0.4375rem);
  bottom: -0.4375rem;
}
.report .report-block-dot.bottom:after {
  height: 0.75rem;
  width: 1px;
  top: 0.4375rem;
  left: calc(50% - 0.5px);
}
.report .report-block-dot.left {
  top: calc(50% - 0.4375rem);
  left: -0.4375rem;
}
.report .report-block-dot.left:after {
  width: 0.75rem;
  height: 1px;
  right: 0.4375rem;
  top: calc(50% - 0.5px);
}
.report .report-block-dot.right {
  top: calc(50% - 0.4375rem);
  right: -0.4375rem;
}
.report .report-block-dot.right:after {
  width: 2.25rem;
  height: 1px;
  left: 0.4375rem;
  top: calc(50% - 0.5px);
}
.report .report-block-dot.lg {
  width: 1.168rem;
  height: 1.168rem;
  border-radius: 1.168rem;
}
.report .report-block-dot.lg.top {
  left: calc(50% - 0.584rem);
  top: -0.584rem;
}
.report .report-block-dot.lg.top:after {
  bottom: 0.584rem;
}
.report .report-block-dot.lg.bottom {
  left: calc(50% - 0.584rem);
  bottom: -0.584rem;
}
.report .report-block-dot.lg.bottom:after {
  top: 0.584rem;
}
.report .report-block-dot.lg.left {
  top: calc(50% - 0.584rem);
  left: -0.584rem;
}
.report .report-block-dot.lg.left:after {
  right: 0.584rem;
}
.report .report-block-dot.lg.right {
  top: calc(50% - 0.584rem);
  right: -0.584rem;
}
.report .report-block-dot.lg.right:after {
  left: 50%;
}
.report .report-block-dot.xl {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 2.125rem;
}
.report .report-block-dot.xl.top {
  left: calc(50% - 1.0625rem);
  top: -1.0625rem;
}
.report .report-block-dot.xl.top:after {
  bottom: 1.0625rem;
}
.report .report-block-dot.xl.bottom {
  left: calc(50% - 1.0625rem);
  bottom: -1.0625rem;
}
.report .report-block-dot.xl.bottom:after {
  top: 1.0625rem;
}
.report .report-block-dot.xl.left {
  top: calc(50% - 1.0625rem);
  left: -1.0625rem;
}
.report .report-block-dot.xl.left:after {
  right: 1.0625rem;
}
.report .report-block-dot.xl.right {
  top: calc(50% - 1.0625rem);
  right: -1.0625rem;
}
.report .report-block-dot.xl.right:after {
  left: 50%;
}

.report-block {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #44aeca;
  height: 100%;
  padding: 1rem;
}
@media (min-width: 768px) {
  .report-block {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .report-block {
    padding: 1rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .report-block {
    padding: 1.5rem 1rem;
  }
}
@media (min-width: 1440px) {
  .report-block {
    padding: 1.875rem;
  }
}
.report-block_lg {
  padding: 1rem;
}
@media (min-width: 768px) {
  .report-block_lg {
    padding: 1rem 1.5rem;
  }
}
@media (min-width: 992px) {
  .report-block_lg {
    padding: 1.5rem 1rem;
  }
}
@media (min-width: 1200px) {
  .report-block_lg {
    padding: 2.5rem 1.5rem;
  }
}
@media (min-width: 1440px) {
  .report-block_lg {
    padding: 3rem 1.875rem;
  }
}
.report-block_household-consumption {
  border-color: #fff;
  flex-direction: row;
  align-items: center;
}
.report-block__title {
  font-size: 1.35rem;
  line-height: 1.17;
  letter-spacing: 0.02em;
  flex-grow: 1;
}
@media (max-width: 1199.98px) {
  .report-block__title {
    font-size: 1.1rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__title {
    font-size: 1rem;
    padding-right: 2rem;
  }
}
.report-block__title_lg {
  font-weight: 300;
  font-size: 2rem;
  padding-top: 0;
  margin-left: 6rem;
  flex-grow: unset;
}
@media (max-width: 1199.98px) {
  .report-block__title_lg {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__title_lg {
    font-size: 1.5rem;
    padding-top: 1rem;
  }
}
.report-block__title_household-consumption {
  margin-left: 1rem;
}
.report-block__description {
  font-size: 0.6785rem;
  line-height: 1.62;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.5);
  margin: 4rem 0 1.5rem;
  height: 3.5rem;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .report-block__description {
    margin: 1rem 0 0.5rem;
    height: auto;
    overflow: inherit;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .report-block__description {
    margin: 1rem 0;
  }
}
.report-block__description_lg {
  margin-top: 2rem;
}
@media (max-width: 991.98px) {
  .report-block__description_lg {
    margin-top: 1rem;
  }
}
.report-block__description_household-consumption {
  margin: 0 2rem;
  padding-right: 0;
  height: auto;
}
.report-block__energy {
  font-weight: 600;
  font-size: 2.75rem;
  letter-spacing: 0.01em;
  color: #44aeca;
  margin-bottom: -0.25em;
  display: flex;
  align-items: baseline;
}
@media (max-width: 1439.98px) {
  .report-block__energy {
    font-size: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__energy {
    font-size: 1.5rem;
  }
}
.report-block__energy_lg {
  font-size: 4.625rem;
  flex-grow: 0;
}
@media (max-width: 1199.98px) {
  .report-block__energy_lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__energy_lg {
    font-size: 2rem;
  }
}
.report-block__energy_household-consumption {
  padding: 0;
}
.report-block__energy-unit {
  font-weight: 300;
  font-size: 1.375rem;
  letter-spacing: 0.02em;
  color: #44aeca;
  display: inline-block;
  margin-left: 0.75rem;
}
@media (max-width: 1199.98px) {
  .report-block__energy-unit {
    font-size: 1.15rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__energy-unit {
    font-size: 0.875rem;
  }
}
.report-block__icon {
  font-size: 2.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
@media (min-width: 768px) {
  .report-block__icon {
    right: 1rem;
    top: 1rem;
  }
}
@media (min-width: 992px) {
  .report-block__icon {
    right: 1rem 0.5rem;
    top: 1rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .report-block__icon {
    right: 1.5rem 1rem;
    top: 1.5rem 1rem;
  }
}
@media (min-width: 1440px) {
  .report-block__icon {
    right: 1.875rem;
    top: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .report-block__icon {
    font-size: 2.15rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__icon {
    font-size: 2rem;
  }
}
.report-block__icon_lg {
  font-size: 4rem;
  top: auto;
  right: auto;
}
@media (max-width: 1199.98px) {
  .report-block__icon_lg {
    font-size: 3rem;
  }
}
@media (max-width: 991.98px) {
  .report-block__icon_lg {
    font-size: 2rem;
  }
}
.report-block__icon_household-consumption {
  font-size: 7rem;
  position: static;
}
@media (max-width: 991.98px) {
  .report-block__icon_household-consumption {
    font-size: 2rem;
  }
}
.report-block__chart {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
@media (min-width: 768px) {
  .report-block__chart {
    right: 1rem;
    bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .report-block__chart {
    right: 1rem 0.5rem;
    bottom: 1rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .report-block__chart {
    right: 1.5rem 1rem;
    bottom: 1.5rem 1rem;
  }
}
@media (min-width: 1440px) {
  .report-block__chart {
    right: 1.875rem;
    bottom: 1.875rem;
  }
}
.report-block__chart_lg {
  position: static;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 991.98px) {
  .report-block__chart_lg {
    position: absolute;
    flex-grow: 0;
  }
}

.report-grid__container {
  display: grid;
  gap: 1rem 0.5rem;
}
@media (min-width: 768px) {
  .report-grid__container {
    gap: 3rem 1.5rem;
  }
}
@media (min-width: 992px) {
  .report-grid__container {
    gap: 1.5rem 2rem;
  }
}
@media (min-width: 1200px) {
  .report-grid__container {
    gap: 2rem 3rem;
  }
}
.report-grid__box {
  position: relative;
}
.report-grid__box_pv-generation {
  grid-area: pv-generation;
}
.report-grid__box_pv-generation-components {
  grid-area: pv-generation-components;
}
.report-grid__box_charged-battery {
  grid-area: charged-battery;
}
.report-grid__box_pv-into-house {
  grid-area: pv-into-house;
}
.report-grid__box_pv-export-to-grid {
  grid-area: pv-export-to-grid;
}
.report-grid__box_grid-usage {
  grid-area: grid-usage;
}
.report-grid__box_grid-usage-components {
  grid-area: grid-usage-components;
}
.report-grid__box_grid-into-house {
  grid-area: grid-into-house;
}
.report-grid__box_grid-to-battery {
  grid-area: grid-to-battery;
}
.report-grid__box_battery-usage {
  grid-area: battery-usage;
}
.report-grid__box_battery-usage-components {
  grid-area: battery-usage-components;
}
.report-grid__box_battery-to-house {
  grid-area: battery-to-house;
}
.report-grid__box_battery-to-grid {
  grid-area: battery-to-grid;
}
.report-grid__box_household-consumption {
  grid-area: household-consumption;
}
.report-grid__box_components {
  display: grid;
  gap: 1rem;
}
@media (min-width: 768px) {
  .report-grid__box_components {
    gap: 3rem;
  }
}
@media (min-width: 992px) {
  .report-grid__box_components {
    gap: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .report-grid__box_components {
    gap: 2rem;
  }
}

.report-summary-block {
  border-width: 3px;
  border-style: solid;
  margin-bottom: 30px;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.report-summary-block:after {
  content: "";
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border-width: 0.625rem;
  border-style: solid;
}
.report-summary-block__icon {
  font-size: 1.625rem;
  position: absolute;
  color: #fff;
  left: 1rem;
  top: 1.5rem;
}
@media (max-width: 767.98px) {
  .report-summary-block__icon {
    font-size: 1rem;
  }
}
.report-summary-block__title {
  color: #fff;
  font-size: 1rem;
  line-height: 1.44;
  letter-spacing: 0.03em;
  flex-grow: 1;
  margin-left: 2.625rem;
  margin-bottom: 1rem;
}
@media (max-width: 1439.98px) {
  .report-summary-block__title {
    font-size: 0.9rem;
  }
}
@media (max-width: 1199.98px) {
  .report-summary-block__title {
    font-size: 0.7rem;
  }
}
@media (max-width: 767.98px) {
  .report-summary-block__title {
    font-size: 0.9rem;
  }
}
.report-summary-block__energy {
  font-weight: 600;
  font-size: 2.06rem;
  letter-spacing: 0.015em;
  margin-bottom: -0.25em;
  display: flex;
  align-items: baseline;
}
@media (max-width: 767.98px) {
  .report-summary-block__energy {
    font-size: 1.5rem;
  }
}
.report-summary-block__energy-unit {
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
  display: inline-block;
  margin-left: 0.75rem;
}
@media (max-width: 767.98px) {
  .report-summary-block__energy-unit {
    font-size: 0.875rem;
  }
}

.settings-block {
  padding: 1.75rem;
  border: 1px solid #616161;
  position: relative;
}
@media (min-width: 992px) {
  .settings-block {
    min-height: 100%;
  }
}
@media (max-width: 991.98px) {
  .settings-block {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .settings-block {
    padding: 1.25rem;
  }
}
@media (min-width: 992px) {
  .settings-block:not(:first-of-type) {
    margin-left: -1px;
  }
}
.settings-block__label {
  font-weight: 300;
  font-size: 1.3125rem;
  padding-left: 1em;
}
@media (max-width: 767.98px) {
  .settings-block__label {
    font-size: 1rem;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .settings-block__label:before, .settings-block__label:after {
    top: 0;
  }
}
.settings-block__label_badge {
  padding-right: 1.625rem;
}
.settings-block__description {
  font-weight: 400;
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
  margin: 1.5rem 0 0 3.5rem;
}
@media (max-width: 767.98px) {
  .settings-block__description {
    margin: 1rem 0 0 3rem;
  }
}
.settings-block__badge {
  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 0.625rem;
  line-height: 1.625rem;
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .settings-block__badge {
    top: 1.25rem;
    right: 1.25rem;
  }
}

.MuiSlider-root {
  color: #44aeca;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px) !important;
}
.MuiSlider-root .MuiSlider-rail {
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2);
}
.MuiSlider-root .MuiSlider-rail:before {
  content: "";
  position: absolute;
  right: calc(-20px + 1px);
  top: 0;
  width: calc(20px - 1px);
  height: 100%;
  background-color: inherit;
}
.MuiSlider-root .MuiSlider-track {
  height: 5px;
  background-color: #44aeca;
}
.MuiSlider-root .MuiSlider-track:before {
  content: "";
  position: absolute;
  left: calc(-20px + 1px);
  top: 0;
  width: calc(20px - 1px);
  height: 100%;
  background-color: inherit;
}
.MuiSlider-root .MuiSlider-mark {
  height: 7px;
  background-color: #000;
}
.MuiSlider-root .MuiSlider-mark[data-index="0"] {
  display: none;
}
.MuiSlider-root .MuiSlider-mark[data-index="10"] {
  display: none;
}
@media (max-width: 767.98px) {
  .MuiSlider-root .MuiSlider-mark {
    display: none;
  }
}
.MuiSlider-root .MuiSlider-markActive {
  opacity: 1;
  background-color: #000;
}
.MuiSlider-root .MuiSlider-markLabel {
  color: #fff;
}
@media (max-width: 767.98px) {
  .MuiSlider-root .MuiSlider-markLabel {
    display: none;
  }
}
.MuiSlider-root .MuiSlider-markLabelActive {
  color: #fff;
}
.MuiSlider-root .MuiSlider-markLabel {
  top: 2.5rem;
}
.MuiSlider-root .MuiSlider-thumb {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  background-color: #000;
  border: 6px solid #44aeca;
}
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel {
  left: calc(-50% + 0.75rem);
  font-size: 1.5rem;
  color: #44aeca;
  background-color: transparent;
}
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel * {
  color: #44aeca;
  background-color: transparent;
}

.rc-time-picker {
  padding: 0;
}

.rc-time-picker-panel {
  padding-top: 3rem;
}

.rc-time-picker-input {
  background-color: #000;
  height: 100%;
  border: 0 none;
  padding: 0.875rem 0.875rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.rc-time-picker-input[disabled] {
  background-color: #000;
  opacity: 0.5;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-combobox {
  color: #000;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
#toast-container > div {
  background-size: 1.25rem 1.25rem;
  background-position: 1.75rem 1.75rem;
  padding: 1.75rem 1.75rem 1.75rem 4rem;
  border-radius: 0;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  box-shadow: none;
}

.toast {
  border: 1px solid #616161;
  background-color: #000;
}

.toast-success {
  border: 1px solid #88D850;
  background-color: #000;
}

.toast-error {
  border: 1px solid #FC1E1E;
  background-color: #000;
}

.toast-info {
  border: 1px solid #44aeca;
  background-color: #000;
}

.toast-warning {
  border: 1px solid #FE9B64;
  background-color: #000;
}

#toast-container > .toast-info {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%2344aeca' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FC1E1E' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%2388D850' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23FE9B64' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e") !important;
}

.toast-title {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.37;
}

.toast-message {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.37;
}

.toast-title + .toast-message {
  margin-top: 1rem;
}

.user-notification {
  padding: 0;
  width: 420px;
}
@media (max-width: 767.98px) {
  .user-notification {
    width: auto;
  }
}
.user-notification__unread-total {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 1.5rem 1.5rem 1.5rem;
  position: relative;
}
.user-notification__unread-total_full {
  background-color: rgba(68, 174, 202, 0.15);
}
.user-notification__clear-btn {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  font-size: 1.25rem;
  color: #616161;
  cursor: pointer;
}
.user-notification__list {
  height: 420px;
  max-height: 420px;
  overflow-y: scroll;
}
.user-notification__list_empty {
  height: 200px;
  max-height: 200px;
}
.user-notification__list-stub {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
}
.user-notification__item {
  padding: 1.5rem 1.5rem 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}
.user-notification__item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  width: calc(100% - 3rem);
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.user-notification__item:last-of-type:after {
  content: none;
}
.user-notification__item_unread {
  background-color: rgba(68, 174, 202, 0.15);
}
.user-notification__icon {
  width: 1.8125rem;
  height: 1.8125rem;
  background-color: #e3f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625rem;
  color: #44aeca;
  margin-right: 1rem;
  flex-shrink: 0;
  position: relative;
}
.user-notification__icon:before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #44aeca;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 1rem;
  mask-size: 1rem;
  -webkit-mask-position: center center;
  mask-position: center center;
}
.user-notification__icon_success-tick {
  background-color: #44aeca;
}
.user-notification__icon_success-tick:before {
  background-color: #fff;
  -webkit-mask-image: url("../assets/icons/check.svg");
  mask-image: url("../assets/icons/check.svg");
}
.user-notification__icon_warning {
  background-color: #fc1e1e;
}
.user-notification__icon_warning:before {
  background-color: #fff;
  -webkit-mask-size: 0.25rem;
  mask-size: 0.25rem;
  -webkit-mask-image: url("../assets/icons/exclamation.svg");
  mask-image: url("../assets/icons/exclamation.svg");
}
.user-notification__icon_info:before {
  -webkit-mask-size: 0.25rem;
  mask-size: 0.25rem;
  -webkit-mask-image: url("../assets/icons/exclamation.svg");
  mask-image: url("../assets/icons/exclamation.svg");
}
.user-notification__icon_cloud-ok:before {
  -webkit-mask-size: 1.25rem;
  mask-size: 1.25rem;
  -webkit-mask-image: url("../assets/icons/cloud.svg");
  mask-image: url("../assets/icons/cloud.svg");
}
.user-notification__icon_cloud-fail:before {
  -webkit-mask-size: 1.25rem;
  mask-size: 1.25rem;
  -webkit-mask-image: url("../assets/icons/cloud.svg");
  mask-image: url("../assets/icons/cloud.svg");
}
.user-notification__icon_plug:before {
  -webkit-mask-size: 1.25rem;
  mask-size: 1.25rem;
  -webkit-mask-image: url("../assets/icons/socket.svg");
  mask-image: url("../assets/icons/socket.svg");
}
.user-notification__icon_file:before {
  -webkit-mask-image: url("../assets/icons/file-4g.svg");
  mask-image: url("../assets/icons/file-4g.svg");
}
.user-notification__icon_wifi:before {
  -webkit-mask-size: 1.25rem;
  mask-size: 1.25rem;
  -webkit-mask-image: url("../assets/icons/wifi.svg");
  mask-image: url("../assets/icons/wifi.svg");
}
.user-notification__icon_bell:before {
  -webkit-mask-image: url("../assets/icons/bell.svg");
  mask-image: url("../assets/icons/bell.svg");
}
.user-notification__text {
  font-size: 0.875rem;
  flex-grow: 1;
}
.user-notification__created {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.625rem;
  margin-left: 1rem;
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .weather {
    border: 0 none;
  }
}
@media (max-width: 767.98px) {
  .weather .card-body {
    padding: 0;
  }
}
.weather .weather-container {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 767.98px) {
  .weather .weather-container {
    width: 520px;
  }
}
.weather .weather-item {
  text-align: center;
  font-size: 1.125rem;
  line-height: 2.22em;
  letter-spacing: 0.03em;
  width: 150px;
  border: 1px solid transparent;
}
.weather .weather-item:hover {
  border-color: #fff;
}
@media (max-width: 767.98px) {
  .weather .weather-item {
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 80px;
  }
}
.weather .weather-hour {
  font-weight: 300;
}
.weather .weather-icon {
  height: 30px;
  width: 30px;
  margin: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
}
.weather .weather-icon.w01d {
  background-image: url("../assets/images/weather-icons/01d.svg");
}
.weather .weather-icon.w01n {
  background-image: url("../assets/images/weather-icons/01n.svg");
}
.weather .weather-icon.w02d {
  background-image: url("../assets/images/weather-icons/02d.svg");
}
.weather .weather-icon.w02n {
  background-image: url("../assets/images/weather-icons/02n.svg");
}
.weather .weather-icon.w03d {
  background-image: url("../assets/images/weather-icons/03d.svg");
}
.weather .weather-icon.w03n {
  background-image: url("../assets/images/weather-icons/03n.svg");
}
.weather .weather-icon.w04d {
  background-image: url("../assets/images/weather-icons/04d.svg");
}
.weather .weather-icon.w04n {
  background-image: url("../assets/images/weather-icons/04n.svg");
}
.weather .weather-icon.w09d {
  background-image: url("../assets/images/weather-icons/09d.svg");
}
.weather .weather-icon.w09n {
  background-image: url("../assets/images/weather-icons/09n.svg");
}
.weather .weather-icon.w10d {
  background-image: url("../assets/images/weather-icons/10d.svg");
}
.weather .weather-icon.w10n {
  background-image: url("../assets/images/weather-icons/10n.svg");
}
.weather .weather-icon.w11d {
  background-image: url("../assets/images/weather-icons/11d.svg");
}
.weather .weather-icon.w11n {
  background-image: url("../assets/images/weather-icons/11n.svg");
}
.weather .weather-icon.w13d {
  background-image: url("../assets/images/weather-icons/13d.svg");
}
.weather .weather-icon.w13n {
  background-image: url("../assets/images/weather-icons/13n.svg");
}
.weather .weather-icon.w50d {
  background-image: url("../assets/images/weather-icons/50d.svg");
}
.weather .weather-icon.w50n {
  background-image: url("../assets/images/weather-icons/50n.svg");
}
.weather .weather-temperature {
  font-weight: 600;
}

.cookies-clause {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  max-height: 400px;
  color: #fff;
  z-index: 9900;
  background-color: #202020;
  border-top: 1px solid #fff;
  font-family: Nunito, sans-serif;
  transition: bottom 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
}
.cookies-clause .energizer-brand-name {
  font-style: "italic";
}
@media (prefers-color-scheme: light) {
  .cookies-clause .energizer-brand-name {
    color: "#000";
  }
}
.cookies-clause, .cookies-clause * {
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cookies-clause a {
  text-decoration: underline;
}
@media (prefers-color-scheme: light) {
  .cookies-clause {
    background-color: #fff;
    border-top: 1px solid #202020;
    color: #202020;
  }
}
.cookies-clause .container {
  padding: 10px 0;
}
@media (max-width: 767px) {
  .cookies-clause .container {
    padding: 15px;
  }
}
.cookies-clause__content {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
}
@media (max-width: 767px) {
  .cookies-clause__content {
    padding-left: 0;
    padding-right: 0;
    display: block;
    min-height: auto;
  }
}
@media (max-width: 767px) {
  .cookies-clause__notice {
    padding-left: 4px;
    margin-bottom: 10px;
  }
}
.cookies-clause__notice p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 0;
}
.cookies-clause__buttons {
  padding-left: 3vw;
  display: flex;
  align-items: center;
}
.cookies-clause__button {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  padding: 8px 30px;
  transition: all 0.3s ease;
  border: none;
  display: inline-block;
  margin: 3px 4px;
  white-space: nowrap;
  letter-spacing: 0;
  cursor: pointer;
}
@media (prefers-color-scheme: dark) {
  .cookies-clause__button {
    color: "#fff";
  }
}
.cookies-clause__button_primary {
  color: #fff;
}
.cookies-clause__button_primary:hover {
  background-color: #000;
}
.cookies-clause__button_reject {
  margin-left: 3rem;
  border: 3px solid transparent;
  padding-left: 0;
  padding-right: 0;
}
.cookies-clause__button_reject:hover {
  border-bottom-color: #000;
  color: #000;
}

.interval-range-modal .modal-content {
  background-color: #fff;
}
.interval-range-modal .modal-content .modal-header {
  border-bottom: 1px solid #44AECA;
}
.interval-range-modal .modal-content .modal-header .modal-title {
  padding: 0;
  color: #606770;
  text-align: left;
  font-size: 1rem;
}
.interval-range-modal .modal-content .modal-header .close::before, .interval-range-modal .modal-content .modal-header .close::after {
  background-color: #000;
}
.interval-range-modal .modal-content .modal-footer {
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .interval-range-modal .modal-content .modal-footer .btn {
    padding: 1.125rem 2rem;
    flex: 1;
  }
}
.interval-range-modal__input {
  background-color: transparent;
  color: #606770;
  font-weight: normal;
  text-align: left;
}
.interval-range-modal__input:focus {
  background-color: transparent;
  color: #606770;
  border: 1px solid #44aeca;
}

.daterangepicker .ranges {
  color: #44aeca;
}
@media (max-width: 991.98px) {
  .daterangepicker .ranges {
    display: none;
  }
}
.daterangepicker .ranges > ul {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
}
.daterangepicker .ranges > ul > li {
  background-color: #f5f5f5;
  border-radius: 3px;
}
.daterangepicker .ranges > ul > li.active {
  background-color: #44aeca !important;
  color: #fff;
}
.daterangepicker.show-calendar .ranges {
  margin-top: 0 !important;
}
.daterangepicker .drp-calendar {
  display: block !important;
}
.daterangepicker .calendar-table th {
  color: #606770;
}
.daterangepicker .calendar-table td {
  color: #606770;
}
.daterangepicker .calendar-table td.active {
  background-color: #44aeca;
  color: #fff;
}
.daterangepicker .calendar-table td.disabled {
  opacity: 0.2;
}
.daterangepicker .drp-selected {
  display: none;
}
.daterangepicker .drp-buttons {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.daterangepicker .drp-buttons .applyBtn, .daterangepicker .drp-buttons .cancelBtn {
  border-radius: 0;
  padding: 4px 1rem;
}
.daterangepicker .drp-buttons .applyBtn {
  background-color: #7edc5d;
  text-transform: uppercase;
  color: #fff;
  border-color: #7edc5d;
  margin: 0;
}
.daterangepicker .drp-buttons .applyBtn:active {
  background-color: #7edc5d;
}
.daterangepicker .drp-buttons .cancelBtn {
  background-color: #fff;
  border-color: #d1dae2;
  color: #606770;
}

.dashboard-page .report-grid__container {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr 1fr;
  grid-template-areas: "pv-generation pv-generation-components household-consumption" "grid-usage grid-usage-components household-consumption" "battery-usage battery-usage-components household-consumption";
  gap: 1rem 0.5rem;
}
@media (min-width: 768px) {
  .dashboard-page .report-grid__container {
    gap: 3rem 1.5rem;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__container {
    gap: 1.5rem 2rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__container {
    gap: 2rem 3rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "pv-generation" "pv-generation-components" "grid-usage" "grid-usage-components" "battery-usage" "battery-usage-components";
  }
}
@media (max-width: 767.98px) {
  .dashboard-page .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "pv-generation" "pv-generation-components" "grid-usage" "grid-usage-components" "battery-usage" "battery-usage-components";
  }
}
.dashboard-page .report-grid__container_pv-generation-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: "charged-battery" "pv-into-house" "pv-export-to-grid";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_pv-generation-components {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
  }
}
@media (max-width: 767.98px) {
  .dashboard-page .report-grid__container_pv-generation-components {
    margin-left: 3rem;
    grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
  }
}
.dashboard-page .report-grid__container_pv-generation-components:before {
  content: "";
  position: absolute;
  top: -1rem;
  left: calc(-1.5rem - 1px);
  width: 1px;
  height: calc(100% - (16.5% - 1rem) + 5px);
  border: 1px solid #44aeca;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_pv-generation-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(16.5% - 0.5rem);
    width: calc(100% - (33% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__container_pv-generation-components:before {
    top: calc(16.5% - 1.5rem / 3);
    left: -0.6666666667rem;
    height: calc(100% - (33% - 3rem / 3) - 2px);
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__container_pv-generation-components:before {
    top: calc(16.5% - 2rem / 3 + 2px);
    left: -1rem;
    height: calc(100% - (33% - 4rem / 3) - 2px);
  }
}
.dashboard-page .report-grid__container_grid-usage-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "grid-into-house" "grid-to-battery";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_grid-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "grid-to-battery grid-into-house";
  }
}
@media (max-width: 767.98px) {
  .dashboard-page .report-grid__container_grid-usage-components {
    margin-left: 3rem;
    grid-template-areas: "grid-to-battery" "grid-into-house";
  }
}
.dashboard-page .report-grid__container_grid-usage-components:before {
  content: "";
  position: absolute;
  top: -1rem;
  left: calc(-1.5rem - 1px);
  width: 1px;
  height: calc(100% - (25% - 1rem) + 5px);
  border: 1px solid #44aeca;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_grid-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__container_grid-usage-components:before {
    top: calc(25% - 1.5rem / 3 + 1px);
    left: -0.6666666667rem;
    height: calc(100% - (50% - 3rem / 3) - 3px);
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__container_grid-usage-components:before {
    top: calc(25% - 2rem / 3 + 2px);
    left: -1rem;
    height: calc(100% - (50% - 4rem / 3) - 4px);
  }
}
.dashboard-page .report-grid__container_battery-usage-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "battery-to-house" "battery-to-grid";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_battery-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "battery-to-grid battery-to-house";
  }
}
@media (max-width: 767.98px) {
  .dashboard-page .report-grid__container_battery-usage-components {
    margin-left: 3rem;
    grid-template-areas: "battery-to-grid" "battery-to-house";
  }
}
.dashboard-page .report-grid__container_battery-usage-components:before {
  content: "";
  position: absolute;
  top: -1rem;
  left: calc(-1.5rem - 1px);
  width: 1px;
  height: calc(100% - (25% - 1rem) + 5px);
  border: 1px solid #44aeca;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__container_battery-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__container_battery-usage-components:before {
    top: calc(25% - 1.5rem / 3 + 1px);
    left: -0.6666666667rem;
    height: calc(100% - (50% - 3rem / 3) - 4px);
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__container_battery-usage-components:before {
    top: calc(25% - 2rem / 3 + 3px);
    left: -1rem;
    height: calc(100% - (50% - 4rem / 3) - 5px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_components {
    margin-right: 3rem;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_household-consumption {
    margin-top: 115%;
    margin-bottom: 115%;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__box_household-consumption {
    margin-top: 107%;
    margin-bottom: 107%;
  }
}
@media (min-width: 1440px) {
  .dashboard-page .report-grid__box_household-consumption {
    margin-top: 90%;
    margin-bottom: 90%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    right: 1.65rem;
    margin-right: -0.584rem;
    margin-bottom: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_household-consumption:before {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    top: -0.4375rem;
    left: 50%;
    margin-left: -0.4375rem;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_household-consumption:after {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    bottom: -0.4375rem;
    left: 50%;
    margin-left: -0.4375rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_battery-usage, .dashboard-page .report-grid__box_pv-generation, .dashboard-page .report-grid__box_grid-usage {
    margin-right: 3rem;
  }
}
.dashboard-page .report-grid__box_battery-usage:before, .dashboard-page .report-grid__box_pv-generation:before, .dashboard-page .report-grid__box_grid-usage:before {
  width: 1.168rem;
  height: 1.168rem;
  border-radius: 1.168rem;
  background-color: #44aeca;
  content: "";
  position: absolute;
  left: 1.5rem;
  margin-left: -0.584rem;
  bottom: -0.584rem;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_battery-usage:before, .dashboard-page .report-grid__box_pv-generation:before, .dashboard-page .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    left: auto;
    right: 50%;
    margin-right: -1.0625rem;
    bottom: -1.0625rem;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_battery-usage:before, .dashboard-page .report-grid__box_pv-generation:before, .dashboard-page .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    left: auto;
    right: -1.0625rem;
    bottom: 50%;
    margin-bottom: -1.0625rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_battery-usage:after, .dashboard-page .report-grid__box_pv-generation:after, .dashboard-page .report-grid__box_grid-usage:after {
    content: "";
    position: absolute;
    bottom: -2rem;
    right: calc(50% - 1px);
    width: 1px;
    height: 2rem;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_battery-usage:after, .dashboard-page .report-grid__box_pv-generation:after, .dashboard-page .report-grid__box_grid-usage:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: -1.3333333333rem;
    width: 1.3333333333rem;
    height: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__box_battery-usage:after, .dashboard-page .report-grid__box_pv-generation:after, .dashboard-page .report-grid__box_grid-usage:after {
    right: -2rem;
    width: 2rem;
  }
}
.dashboard-page .report-grid__box_battery-to-grid:before, .dashboard-page .report-grid__box_battery-to-house:before, .dashboard-page .report-grid__box_charged-battery:before, .dashboard-page .report-grid__box_pv-export-to-grid:before, .dashboard-page .report-grid__box_pv-into-house:before, .dashboard-page .report-grid__box_grid-to-battery:before, .dashboard-page .report-grid__box_grid-into-house:before {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.875rem;
  background-color: #44aeca;
  content: "";
  position: absolute;
  left: -0.4375rem;
  top: 50%;
  margin-top: -0.4375rem;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_battery-to-grid:before, .dashboard-page .report-grid__box_battery-to-house:before, .dashboard-page .report-grid__box_charged-battery:before, .dashboard-page .report-grid__box_pv-export-to-grid:before, .dashboard-page .report-grid__box_pv-into-house:before, .dashboard-page .report-grid__box_grid-to-battery:before, .dashboard-page .report-grid__box_grid-into-house:before {
    left: 50%;
    top: 0;
    margin-left: -0.4375rem;
  }
}
.dashboard-page .report-grid__box_battery-to-grid:after, .dashboard-page .report-grid__box_battery-to-house:after, .dashboard-page .report-grid__box_charged-battery:after, .dashboard-page .report-grid__box_pv-export-to-grid:after, .dashboard-page .report-grid__box_pv-into-house:after, .dashboard-page .report-grid__box_grid-to-battery:after, .dashboard-page .report-grid__box_grid-into-house:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: -1.5rem;
  width: 1.5rem;
  height: 1px;
  border: 1px solid #44aeca;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-grid__box_battery-to-grid:after, .dashboard-page .report-grid__box_battery-to-house:after, .dashboard-page .report-grid__box_charged-battery:after, .dashboard-page .report-grid__box_pv-export-to-grid:after, .dashboard-page .report-grid__box_pv-into-house:after, .dashboard-page .report-grid__box_grid-to-battery:after, .dashboard-page .report-grid__box_grid-into-house:after {
    left: 50%;
    width: 1px;
    height: 1rem;
    top: -1rem;
    margin-left: -1px;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-grid__box_battery-to-grid:after, .dashboard-page .report-grid__box_battery-to-house:after, .dashboard-page .report-grid__box_charged-battery:after, .dashboard-page .report-grid__box_pv-export-to-grid:after, .dashboard-page .report-grid__box_pv-into-house:after, .dashboard-page .report-grid__box_grid-to-battery:after, .dashboard-page .report-grid__box_grid-into-house:after {
    left: -0.6666666667rem;
    width: 0.6666666667rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-grid__box_battery-to-grid:after, .dashboard-page .report-grid__box_battery-to-house:after, .dashboard-page .report-grid__box_charged-battery:after, .dashboard-page .report-grid__box_pv-export-to-grid:after, .dashboard-page .report-grid__box_pv-into-house:after, .dashboard-page .report-grid__box_grid-to-battery:after, .dashboard-page .report-grid__box_grid-into-house:after {
    left: -1rem;
    width: 1rem;
  }
}
.dashboard-page .report-block__title {
  margin-right: 2.5rem;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__title {
    margin-right: 2rem;
  }
}
.dashboard-page .report-block__title_lg {
  font-size: 2.125rem;
  margin: 2rem 0 0;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__title_lg {
    font-size: 2rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__title_lg {
    font-size: 1.5rem;
    margin-top: 0;
    margin-left: 3.5rem;
    padding: 0;
  }
}
.dashboard-page .report-block__title_lg br {
  display: none !important;
}
.dashboard-page .report-block__title_big-chart {
  margin-top: 7rem;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__title_big-chart {
    margin-top: 5rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__title_big-chart {
    margin-top: 0;
  }
}
.dashboard-page .report-block__title_big-chart br {
  display: inherit !important;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block__title_household-consumption {
    margin-left: 0;
    margin-top: 2rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__title_self-sufficiency {
    margin: 0 auto 1rem;
  }
}
.dashboard-page .report-block__description {
  margin-top: 3rem;
}
.dashboard-page .report-block__description_lg {
  margin-top: 2rem;
  flex-grow: 1;
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__description_lg {
    margin-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__description {
    margin-top: 1rem;
  }
}
.dashboard-page .report-block__description_household-consumption {
  flex-grow: 0;
}
.dashboard-page .report-block__icon {
  font-size: 3.5rem;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__icon {
    font-size: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__icon {
    font-size: 1.5rem;
  }
}
.dashboard-page .report-block__icon_lg {
  font-size: 4.125rem;
  position: relative;
  display: block;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__icon_lg {
    font-size: 3rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__icon_lg {
    font-size: 2rem;
    position: absolute;
  }
}
.dashboard-page .report-block__icon_big-chart {
  font-size: 7.5rem;
}
@media (max-width: 1199.98px) {
  .dashboard-page .report-block__icon_big-chart {
    font-size: 4rem;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__icon_big-chart {
    font-size: 2.5rem;
  }
}
.dashboard-page .report-block__icon_household-consumption {
  font-size: 5rem;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block__icon_household-consumption {
    font-size: 2.5rem;
    left: calc(25% - 1.25rem);
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__icon_household-consumption {
    font-size: 2.5rem;
    top: 1rem;
  }
}
.dashboard-page .report-block__chart_household-consumption {
  flex-grow: 0;
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block__chart_household-consumption {
    position: static;
    margin: auto;
  }
}
.dashboard-page .report-block__chart_self-sufficiency {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .dashboard-page .report-block__chart_self-sufficiency {
    max-width: 200px;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block__chart_self-sufficiency {
    max-width: 275px;
  }
}
.dashboard-page .report-block__energy {
  margin-top: 2rem;
}
.dashboard-page .report-block__energy_household-consumption {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .dashboard-page .report-block__energy_household-consumption {
    margin-left: 0;
    margin-right: 0;
  }
}
.dashboard-page .report-block_household-consumption {
  border-color: #fff;
  flex-direction: column;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block_household-consumption {
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .dashboard-page .report-block_household-consumption {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block_household-consumption:before {
    content: "";
    position: absolute;
    right: 1.5rem;
    margin-left: -1px;
    bottom: calc(-1 * 500% + 0.7rem);
    height: calc(500% - 0.7rem);
    width: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_household-consumption:before {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: calc(-1 * 0.6666666667rem - 1.5rem - 5px);
    height: calc(0.6666666667rem + 1.5rem + 5px);
    width: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_household-consumption:before {
    top: calc(-1 * 1rem - 0.5rem - 2px);
    height: calc(1rem + 0.5rem + 2px);
  }
}
@media (min-width: 1440px) {
  .dashboard-page .report-block_household-consumption:before {
    top: calc(-1 * 1rem - 5px);
    height: calc(1rem + 5px);
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_household-consumption:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -1px;
    bottom: calc(-1 * 0.6666666667rem - 1.5rem - 4px);
    height: calc(0.6666666667rem + 1.5rem + 4px);
    width: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_household-consumption:after {
    bottom: calc(-1 * 1rem - 0.5rem - 1px);
    height: calc(1rem + 0.5rem - 1px);
  }
}
@media (min-width: 1440px) {
  .dashboard-page .report-block_household-consumption:after {
    bottom: calc(-1 * 1rem - 4px);
    height: calc(1rem + 4px);
  }
}
.dashboard-page .report-block__part {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block__part {
    height: 100%;
    width: 50%;
  }
}
@media (max-width: 991.98px) {
  .dashboard-page .report-block_to-household-consumption {
    border-color: #fff;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_to-household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    right: -0.584rem;
    top: 50%;
    margin-top: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: -1.5rem;
    width: 1.5rem;
    height: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: -1rem;
    width: 1rem;
    height: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_pv-into-house:before {
    top: 2rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_pv-into-house:before {
    top: 3rem;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_pv-into-house:after {
    top: 2rem;
    right: calc(-50% - 2rem - 3px);
    width: calc(50% + 2rem + 3px);
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_pv-into-house:after {
    top: 3rem;
    right: calc(-50% - 3rem - 1px);
    width: calc(50% + 3rem + 1px);
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_grid-into-house:after {
    right: -2rem;
    width: 2rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_grid-into-house:after {
    right: -3rem;
    width: 3rem;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_battery-to-house:before {
    top: auto;
    bottom: 2rem;
    margin-top: 0;
    margin-bottom: -0.584rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_battery-to-house:before {
    bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block_battery-to-house:after {
    top: auto;
    bottom: 2rem;
    right: calc(-50% - 2rem - 3px);
    width: calc(50% + 2rem + 3px);
  }
}
@media (min-width: 1200px) {
  .dashboard-page .report-block_battery-to-house:after {
    bottom: 3rem;
    right: calc(-50% - 3rem - 1px);
    width: calc(50% + 3rem + 1px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page .report-block__hr_household-consumption {
    width: 1px;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media (min-width: 992px) {
  .dashboard-page .report-block__hr_household-consumption:after {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: -0.4375rem;
    top: 50%;
    margin-top: -0.4375rem;
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .device-link-page__modal {
    padding-right: 250px;
    position: relative;
  }
}
@media (min-width: 1200px) {
  .device-link-page__modal:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    border-left: 1px solid #fff;
    background: url("../assets/images/device.svg") no-repeat center center transparent;
    background-size: 99px 270px;
  }
}
@media (min-width: 1200px) {
  .device-link-page__modal .close {
    right: -250px;
    z-index: 1000;
  }
}
.device-link-page__form-group {
  position: relative;
}
.device-link-page__form-control {
  text-align: center;
}
.device-link-page__exclamation {
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 0.625rem);
  background: #44aeca;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  cursor: pointer;
}
.device-link-page__remind-verification-code-btn {
  font-size: 0.75rem;
  margin: auto;
}
.device-link-page__remind-title {
  font-size: 1.5rem;
  font-weight: 300;
}
.device-link-page__icon {
  font-size: 5.175rem;
  color: #44aeca;
  margin-top: 1rem;
  margin-bottom: 3.25rem;
}
.device-link-page__icon_email {
  font-size: 5.75rem;
}
.device-link-page__icon_emoji-sad {
  color: #fc1e1e;
  margin-top: 5.75rem;
  margin-bottom: 5.75rem;
}
.device-link-page__icon_emoji-smile {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem;
}

.page-faq__container {
  height: calc(100vh - 400px);
  min-height: 400px;
  display: flex;
  font-weight: 300;
  width: 100%;
}
@media (max-width: 767.98px) {
  .page-faq__container {
    flex-direction: column;
  }
}
.page-faq__question-list {
  width: 450px;
  min-width: 450px;
  max-height: 100%;
  overflow-y: auto;
}
@media (max-width: 1199.98px) {
  .page-faq__question-list {
    width: 300px;
    min-width: 300px;
  }
}
@media (max-width: 991.98px) {
  .page-faq__question-list {
    width: 250px;
    min-width: 250px;
  }
}
@media (max-width: 767.98px) {
  .page-faq__question-list {
    display: none;
  }
}
.page-faq__question-list_select {
  display: none;
}
@media (max-width: 767.98px) {
  .page-faq__question-list_select {
    display: block;
  }
}
.page-faq__question-item {
  border: 1px solid #6E757B;
  padding: 1.75rem 1.75rem 1.75rem 5.375rem;
  position: relative;
  font-size: 1.3rem;
  line-height: 1.57;
  cursor: pointer;
}
.page-faq__question-item:hover {
  border-color: #44aeca;
  color: #44aeca;
}
.page-faq__question-item_active {
  background-color: #44aeca;
  border-color: #44aeca;
}
.page-faq__question-item_active:hover {
  color: #fff;
}
@media (max-width: 991.98px) {
  .page-faq__question-item {
    padding: 1.25rem 1.25rem 1.25rem 2.75rem;
    font-size: 1rem;
  }
}
.page-faq__question-item-icon {
  position: absolute;
  top: 1.75rem;
  left: 2.25rem;
}
@media (max-width: 991.98px) {
  .page-faq__question-item-icon {
    top: 1.25rem;
    left: 1rem;
  }
}
.page-faq__answer {
  height: 100%;
  border: 1px solid #6E757B;
  margin-right: -1px;
  padding: 3rem;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.6;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}
@media (max-width: 991.98px) {
  .page-faq__answer {
    padding: 2rem;
    font-size: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .page-faq__answer {
    margin-top: 1rem;
  }
}
.page-faq__answer-stub {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.37;
  flex-direction: column;
  text-align: center;
}
.page-faq__answer-stub-icon {
  font-size: 8.75rem;
  margin-bottom: 4.5rem;
}
@media (max-width: 1199.98px) {
  .page-faq__answer-stub-icon {
    font-size: 5rem;
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .page-faq__answer-stub-icon {
    font-size: 4rem;
    margin-bottom: 3rem;
  }
}
.page-faq__search {
  position: relative;
  width: 320px;
}
@media (max-width: 767.98px) {
  .page-faq__search {
    width: 100%;
    margin-top: 1rem;
  }
}
.page-faq__search-control {
  width: 100%;
}
.page-faq__search-button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 47px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.page-lifetime-report .report-grid__container {
  grid-template-columns: auto 1fr;
  grid-template-rows: auto repeat(3, 1fr);
  grid-template-areas: "household-consumption household-consumption" "battery-usage battery-usage-components" "pv-generation pv-generation-components" "grid-usage grid-usage-components";
  gap: 1rem 0.5rem;
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container {
    gap: 0.5rem 1rem;
  }
}
@media (min-width: 768px) {
  .page-lifetime-report .report-grid__container {
    gap: 3rem 1.5rem;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container {
    gap: 1.5rem 3rem;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__container {
    gap: 1.5rem 2rem;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container {
    gap: 2rem 1.5rem;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container {
    gap: 2rem 3rem;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container {
    gap: 3rem 2rem;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "pv-generation" "pv-generation-components" "grid-usage" "grid-usage-components" "battery-usage" "battery-usage-components";
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "pv-generation" "pv-generation-components" "grid-usage" "grid-usage-components" "battery-usage" "battery-usage-components";
  }
}
.page-lifetime-report .report-grid__container_battery-usage-components {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "battery-to-grid battery-to-house";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_battery-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "battery-to-grid battery-to-house";
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-grid__container_battery-usage-components {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    margin-left: 3rem;
    grid-template-areas: "battery-to-grid" "battery-to-house";
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_battery-usage-components:before {
    content: "";
    position: absolute;
    top: -1rem;
    left: calc(-1.5rem - 1px);
    width: 1px;
    height: calc(100% - (25% - 1rem) + 5px);
    border: 1px solid #44aeca;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_battery-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__container_battery-usage-components:after {
    content: "";
    position: absolute;
    top: -1.5rem;
    right: -4rem;
    width: 1px;
    height: calc(250% + 12rem + 4.5rem);
    border: 1px solid #fff;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__container_battery-usage-components:after {
    top: -3rem;
    height: calc(250% + 12rem + 9rem);
  }
}
.page-lifetime-report .report-grid__container_pv-generation-components {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_pv-generation-components {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-grid__container_pv-generation-components {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin-left: 3rem;
    grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_pv-generation-components:before {
    content: "";
    position: absolute;
    top: -1rem;
    left: calc(-1.5rem - 1px);
    width: 1px;
    height: calc(100% - (16.5% - 1rem) + 5px);
    border: 1px solid #44aeca;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_pv-generation-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(16.5% - 0.5rem);
    width: calc(100% - (33% - 3rem / 3) - 2px);
    height: 1px;
  }
}
.page-lifetime-report .report-grid__container_grid-usage-components {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "grid-to-battery grid-into-house";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_grid-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "grid-to-battery grid-into-house";
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-grid__container_grid-usage-components {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    margin-left: 3rem;
    grid-template-areas: "grid-to-battery" "grid-into-house";
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_grid-usage-components:before {
    content: "";
    position: absolute;
    top: -1rem;
    left: calc(-1.5rem - 1px);
    width: 1px;
    height: calc(100% - (25% - 1rem) + 5px);
    border: 1px solid #44aeca;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__container_grid-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__box_components {
    margin-bottom: 6rem;
    margin-right: 8rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-lifetime-report .report-grid__box_components {
    margin-right: 6rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_components {
    margin-right: 3rem;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__box_components:before {
    content: "";
    position: absolute;
    bottom: -3rem;
    left: -1.5rem;
    width: calc(70% + 4rem + 0.5rem - 1rem + 2px);
    height: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__box_components:before {
    left: -2rem;
    width: calc(75% + 4rem - 1.5rem);
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__box_components.report-grid__container_pv-generation-components:before {
    width: calc(84.1666666667% - 10px + 2rem);
  }
}
@media (min-width: 1200px) {
  .page-lifetime-report .report-grid__box_components.report-grid__container_pv-generation-components:before {
    width: calc(84.1666666667% - 0.5rem - 12px + 3rem);
  }
}
@media (min-width: 1440px) {
  .page-lifetime-report .report-grid__box_components.report-grid__container_pv-generation-components:before {
    width: calc(84.1666666667% - 0.5rem - 3px + 3rem);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    right: 1.65rem;
    margin-right: -0.584rem;
    margin-bottom: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-usage, .page-lifetime-report .report-grid__box_pv-generation, .page-lifetime-report .report-grid__box_grid-usage {
    margin-right: 3rem;
  }
}
.page-lifetime-report .report-grid__box_battery-usage:before, .page-lifetime-report .report-grid__box_pv-generation:before, .page-lifetime-report .report-grid__box_grid-usage:before {
  width: 1.168rem;
  height: 1.168rem;
  border-radius: 1.168rem;
  background-color: #44aeca;
  content: "";
  position: absolute;
  left: 1.5rem;
  margin-left: -0.584rem;
  bottom: -0.584rem;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-usage:before, .page-lifetime-report .report-grid__box_pv-generation:before, .page-lifetime-report .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    left: auto;
    right: 50%;
    margin-right: -1.0625rem;
    bottom: -1.0625rem;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__box_battery-usage:before, .page-lifetime-report .report-grid__box_pv-generation:before, .page-lifetime-report .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    left: auto;
    right: -1.0625rem;
    bottom: 3rem;
    margin-right: 0;
    margin-bottom: -1.0625rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-usage:after, .page-lifetime-report .report-grid__box_pv-generation:after, .page-lifetime-report .report-grid__box_grid-usage:after {
    content: "";
    position: absolute;
    bottom: -2rem;
    right: calc(50% - 1px);
    width: 1px;
    height: 2rem;
    border: 1px solid #44aeca;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-to-grid:before, .page-lifetime-report .report-grid__box_battery-to-house:before, .page-lifetime-report .report-grid__box_charged-battery:before, .page-lifetime-report .report-grid__box_pv-export-to-grid:before, .page-lifetime-report .report-grid__box_pv-into-house:before, .page-lifetime-report .report-grid__box_grid-to-battery:before, .page-lifetime-report .report-grid__box_grid-into-house:before {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: -0.4375rem;
    top: 50%;
    margin-top: -0.4375rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-to-grid:before, .page-lifetime-report .report-grid__box_battery-to-house:before, .page-lifetime-report .report-grid__box_charged-battery:before, .page-lifetime-report .report-grid__box_pv-export-to-grid:before, .page-lifetime-report .report-grid__box_pv-into-house:before, .page-lifetime-report .report-grid__box_grid-to-battery:before, .page-lifetime-report .report-grid__box_grid-into-house:before {
    left: 50%;
    top: 0;
    margin-left: -0.4375rem;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-grid__box_battery-to-grid:before, .page-lifetime-report .report-grid__box_battery-to-house:before, .page-lifetime-report .report-grid__box_charged-battery:before, .page-lifetime-report .report-grid__box_pv-export-to-grid:before, .page-lifetime-report .report-grid__box_pv-into-house:before, .page-lifetime-report .report-grid__box_grid-to-battery:before, .page-lifetime-report .report-grid__box_grid-into-house:before {
    content: "";
    position: absolute;
    bottom: -3rem;
    left: 50%;
    width: 1px;
    height: 3rem;
    border: 1px solid #44aeca;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-to-grid:after, .page-lifetime-report .report-grid__box_battery-to-house:after, .page-lifetime-report .report-grid__box_charged-battery:after, .page-lifetime-report .report-grid__box_pv-export-to-grid:after, .page-lifetime-report .report-grid__box_pv-into-house:after, .page-lifetime-report .report-grid__box_grid-to-battery:after, .page-lifetime-report .report-grid__box_grid-into-house:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: -1.5rem;
    width: 1.5rem;
    height: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-grid__box_battery-to-grid:after, .page-lifetime-report .report-grid__box_battery-to-house:after, .page-lifetime-report .report-grid__box_charged-battery:after, .page-lifetime-report .report-grid__box_pv-export-to-grid:after, .page-lifetime-report .report-grid__box_pv-into-house:after, .page-lifetime-report .report-grid__box_grid-to-battery:after, .page-lifetime-report .report-grid__box_grid-into-house:after {
    left: 50%;
    width: 1px;
    height: 1rem;
    top: -1rem;
    margin-left: -1px;
  }
}
.page-lifetime-report .report-block__title {
  padding-right: 2.5rem;
}
.page-lifetime-report .report-block__title_lg {
  font-size: 1.5rem;
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__title_lg {
    font-size: 1.5rem;
    margin: 0 0 0 3.5rem;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__title_lg br {
    display: none !important;
  }
}
.page-lifetime-report .report-block__description {
  margin-top: 1rem;
}
.page-lifetime-report .report-block__description_lg {
  margin-top: 2rem;
  flex-grow: 1;
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__description_lg {
    margin-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__description {
    margin-top: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .page-lifetime-report .report-block__icon {
    font-size: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__icon {
    font-size: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .page-lifetime-report .report-block__icon_lg {
    font-size: 3rem;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__icon_lg {
    font-size: 2rem;
    position: absolute;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__icon_household-consumption {
    font-size: 2.5rem;
    top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-block__icon_household-consumption {
    left: 1rem;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block__energy {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-block__energy_household-consumption {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-block__energy_household-consumption {
    margin-right: auto;
  }
}
@media (max-width: 991.98px) {
  .page-lifetime-report .report-block_household-consumption {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .page-lifetime-report .report-block_household-consumption {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-block_household-consumption:before {
    content: "";
    position: absolute;
    right: 1.5rem;
    margin-left: -1px;
    bottom: calc(-1 * 1400% + 1rem);
    height: calc(1400% - 1rem);
    width: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-block_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: -0.584rem;
    right: 4rem;
    margin-right: -0.584rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-lifetime-report .report-block_household-consumption:before {
    right: 2rem;
  }
}
.page-lifetime-report .report-block_to-household-consumption {
  border-color: #fff;
}
@media (min-width: 992px) {
  .page-lifetime-report .report-block_to-household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #fff;
    content: "";
    position: absolute;
    right: -0.584rem;
    top: 50%;
    margin-top: -0.584rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-lifetime-report .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: -1.5rem;
    width: 1.5rem;
    height: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .page-lifetime-report .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -4rem;
    margin-top: -1px;
    width: 4rem;
    height: 1px;
    border: 1px solid #fff;
  }
}

.page-monthly-report .report-grid__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr auto;
  gap: 4rem 3rem;
  grid-template-areas: "battery-usage pv-generation grid-usage" "battery-usage-components pv-generation-components grid-usage-components" "household-consumption household-consumption household-consumption";
  gap: 0.5rem 1rem;
}
@media (min-width: 768px) {
  .page-monthly-report .report-grid__container {
    gap: 1.5rem 3rem;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container {
    gap: 2rem 1.5rem;
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-grid__container {
    gap: 3rem 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container {
    gap: 3rem 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "battery-usage" "pv-generation-components" "pv-generation" "grid-usage-components" "grid-usage" "battery-usage-components";
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-grid__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: "household-consumption" "battery-usage" "pv-generation-components" "pv-generation" "grid-usage-components" "grid-usage" "battery-usage-components";
  }
}
.page-monthly-report .report-grid__container_battery-usage-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "battery-to-grid" "battery-to-house";
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_battery-usage-components {
    margin-left: 3rem;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_battery-usage-components {
    margin-bottom: 8rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_battery-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "battery-to-grid battery-to-house";
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-grid__container_battery-usage-components {
    margin-left: 3rem;
  }
}
.page-monthly-report .report-grid__container_battery-usage-components:before {
  content: "";
  position: absolute;
  width: 1px;
  border: 1px solid #44aeca;
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_battery-usage-components:before {
    top: -1rem;
    left: calc(-1.5rem - 1px);
    height: calc(100% - (25% - 1rem) + 2px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_battery-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_battery-usage-components:before {
    top: -2rem;
    left: calc(-1.5rem - 1px - 1.0625rem);
    height: calc(100% - (25% - 2rem) + 0.5rem + 2px);
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-grid__container_battery-usage-components:before {
    top: -3rem;
    height: calc(100% - (25% - 3rem) + 1rem - 2px);
  }
}
.page-monthly-report .report-grid__container_pv-generation-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_pv-generation-components {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-grid__container_pv-generation-components {
    margin-left: 3rem;
    grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
  }
}
.page-monthly-report .report-grid__container_pv-generation-components:before {
  content: "";
  position: absolute;
  width: 1px;
  border: 1px solid #44aeca;
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_pv-generation-components:before {
    top: -1rem;
    left: calc(-1.5rem - 1px);
    height: calc(100% - (16.5% - 1rem) + 2px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_pv-generation-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(16.5% - 0.5rem);
    width: calc(100% - (33% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_pv-generation-components:before {
    top: -2rem;
    left: calc(-1.5rem - 1px - 1.0625rem);
    height: calc(100% - (16.5% - 2rem) + 0.5rem + 3px);
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-grid__container_pv-generation-components:before {
    top: -3rem;
    height: calc(100% - (16.5% - 3rem) + 1rem + 1px);
  }
}
.page-monthly-report .report-grid__container_grid-usage-components {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "grid-to-battery" "grid-into-house";
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_grid-usage-components {
    margin-bottom: 8rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_grid-usage-components {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "grid-to-battery grid-into-house";
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-grid__container_grid-usage-components {
    margin-left: 3rem;
  }
}
.page-monthly-report .report-grid__container_grid-usage-components:before {
  content: "";
  position: absolute;
  width: 1px;
  border: 1px solid #44aeca;
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_grid-usage-components:before {
    top: -1rem;
    left: calc(-1.5rem - 1px);
    height: calc(100% - (25% - 1rem) + 2px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__container_grid-usage-components:before {
    top: calc(1.5rem / 3 * 2 * -1 - 1px);
    left: calc(25% - 0.5rem + 1px);
    width: calc(100% - (50% - 3rem / 3) - 2px);
    height: 1px;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__container_grid-usage-components:before {
    top: -2rem;
    left: calc(-1.5rem - 1px - 1.0625rem);
    height: calc(100% - (25% - 2rem) + 0.5rem + 2px);
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-grid__container_grid-usage-components:before {
    top: -3rem;
    height: calc(100% - (25% - 3rem) + 1rem - 2px);
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_components {
    margin-left: 6rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_components {
    margin-right: 3rem;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_household-consumption {
    margin-top: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    right: 1.65rem;
    margin-right: -0.584rem;
    margin-bottom: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: 3rem;
    top: -0.584rem;
    margin-left: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_household-consumption:after {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: calc(100% - 3.35rem);
    top: -0.584rem;
    margin-left: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-grid__box_household-consumption:after {
    left: calc(100% - 7rem + 4px);
  }
}
@media (min-width: 1440px) {
  .page-monthly-report .report-grid__box_household-consumption:after {
    left: calc(83.5% + 3px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-usage, .page-monthly-report .report-grid__box_pv-generation, .page-monthly-report .report-grid__box_grid-usage {
    margin-right: 3rem;
  }
}
.page-monthly-report .report-grid__box_battery-usage:before, .page-monthly-report .report-grid__box_pv-generation:before, .page-monthly-report .report-grid__box_grid-usage:before {
  width: 1.168rem;
  height: 1.168rem;
  border-radius: 1.168rem;
  background-color: #44aeca;
  content: "";
  position: absolute;
  left: 1.5rem;
  margin-left: -0.584rem;
  bottom: -0.584rem;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-usage:before, .page-monthly-report .report-grid__box_pv-generation:before, .page-monthly-report .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    left: auto;
    right: 50%;
    margin-right: -1.0625rem;
    bottom: -1.0625rem;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_battery-usage:before, .page-monthly-report .report-grid__box_pv-generation:before, .page-monthly-report .report-grid__box_grid-usage:before {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    border: 0.375rem solid #44aeca;
    background-color: #000;
    bottom: 0;
    left: 3rem;
    margin-bottom: -1.0625rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-usage:after, .page-monthly-report .report-grid__box_pv-generation:after, .page-monthly-report .report-grid__box_grid-usage:after {
    content: "";
    position: absolute;
    bottom: -2rem;
    right: calc(50% - 1px);
    width: 1px;
    height: 2rem;
    border: 1px solid #44aeca;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-to-grid:before, .page-monthly-report .report-grid__box_battery-to-house:before, .page-monthly-report .report-grid__box_charged-battery:before, .page-monthly-report .report-grid__box_pv-export-to-grid:before, .page-monthly-report .report-grid__box_pv-into-house:before, .page-monthly-report .report-grid__box_grid-to-battery:before, .page-monthly-report .report-grid__box_grid-into-house:before {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: -0.4375rem;
    top: 50%;
    margin-top: -0.4375rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-to-grid:before, .page-monthly-report .report-grid__box_battery-to-house:before, .page-monthly-report .report-grid__box_charged-battery:before, .page-monthly-report .report-grid__box_pv-export-to-grid:before, .page-monthly-report .report-grid__box_pv-into-house:before, .page-monthly-report .report-grid__box_grid-to-battery:before, .page-monthly-report .report-grid__box_grid-into-house:before {
    left: 50%;
    top: 0;
    margin-left: -0.4375rem;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-grid__box_battery-to-grid:before, .page-monthly-report .report-grid__box_battery-to-house:before, .page-monthly-report .report-grid__box_charged-battery:before, .page-monthly-report .report-grid__box_pv-export-to-grid:before, .page-monthly-report .report-grid__box_pv-into-house:before, .page-monthly-report .report-grid__box_grid-to-battery:before, .page-monthly-report .report-grid__box_grid-into-house:before {
    content: "";
    position: absolute;
    left: -2.5rem;
    top: 50%;
    height: 1px;
    width: 2.5rem;
    border: 1px solid #44aeca;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-to-grid:after, .page-monthly-report .report-grid__box_battery-to-house:after, .page-monthly-report .report-grid__box_charged-battery:after, .page-monthly-report .report-grid__box_pv-export-to-grid:after, .page-monthly-report .report-grid__box_pv-into-house:after, .page-monthly-report .report-grid__box_grid-to-battery:after, .page-monthly-report .report-grid__box_grid-into-house:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: -1.5rem;
    width: 1.5rem;
    height: 1px;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-grid__box_battery-to-grid:after, .page-monthly-report .report-grid__box_battery-to-house:after, .page-monthly-report .report-grid__box_charged-battery:after, .page-monthly-report .report-grid__box_pv-export-to-grid:after, .page-monthly-report .report-grid__box_pv-into-house:after, .page-monthly-report .report-grid__box_grid-to-battery:after, .page-monthly-report .report-grid__box_grid-into-house:after {
    left: 50%;
    width: 1px;
    height: 1rem;
    top: -1rem;
    margin-left: -1px;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block__title {
    margin-right: 4rem;
  }
}
.page-monthly-report .report-block__title_lg {
  font-size: 1.5rem;
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__title_lg {
    font-size: 1.5rem;
    margin: 0 0 0 3.5rem;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block__title_lg {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-monthly-report .report-block__title_lg {
    margin-left: 4rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__title_lg br {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__description_lg {
    margin-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__description {
    margin-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__icon {
    font-size: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .page-monthly-report .report-block__icon_lg {
    font-size: 3rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__icon_lg {
    font-size: 2rem;
    position: absolute;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__icon_household-consumption {
    font-size: 2.5rem;
    top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-block__icon_household-consumption {
    left: 1rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block__energy {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-block__energy_household-consumption {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-block__energy_household-consumption {
    margin-right: auto;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block_to-household-consumption {
    border-color: #fff;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block_to-household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: 3rem;
    bottom: -0.584rem;
    margin-left: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: -1.5rem;
    width: 1.5rem;
    height: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    bottom: -12rem;
    left: calc(3rem - 1px);
    width: 1px;
    height: 12rem;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-block_to-household-consumption:after {
    content: "";
    position: absolute;
    bottom: -13rem;
    left: calc(3rem - 1px);
    width: 1px;
    height: 13rem;
    border: 1px solid #44aeca;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block_pv-into-house:after {
    bottom: -4rem;
    height: 4rem;
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-block_pv-into-house:after {
    bottom: -5rem;
    height: 5rem;
  }
}
@media (max-width: 991.98px) {
  .page-monthly-report .report-block_household-consumption {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .page-monthly-report .report-block_household-consumption {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-monthly-report .report-block_household-consumption:before {
    content: "";
    position: absolute;
    right: 1.5rem;
    margin-left: -1px;
    bottom: calc(-1 * 1400% + 1rem);
    height: calc(1400% - 1rem);
    width: 1px;
    border: 1px solid #fff;
  }
}
@media (min-width: 992px) {
  .page-monthly-report .report-block_household-consumption:before {
    width: 1.168rem;
    height: 1.168rem;
    border-radius: 1.168rem;
    background-color: #44aeca;
    content: "";
    position: absolute;
    left: calc(50% - 0.15rem);
    top: -0.584rem;
    margin-left: -0.584rem;
    z-index: 1;
  }
}
@media (min-width: 1200px) {
  .page-monthly-report .report-block_household-consumption:before {
    left: calc(50% - 2rem + 3px);
  }
}
@media (min-width: 1440px) {
  .page-monthly-report .report-block_household-consumption:before {
    left: calc(50% - 4.5rem + 3px);
  }
}

@media (min-width: 768px) {
  .profile-page__user-data {
    background: url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%2344aeca' d='M55.475 23.579c0-13.022-10.51-23.579-23.475-23.579s-23.475 10.557-23.475 23.579c0 8.126 4.093 15.292 10.32 19.532-8.95 3.837-15.901 11.472-18.846 20.889h7.122c3.98-9.875 13.619-16.842 24.878-16.842s20.898 6.966 24.878 16.842h7.122c-2.944-9.417-9.895-17.051-18.846-20.889 6.228-4.24 10.32-11.406 10.32-19.532zM48.768 23.579c0 9.302-7.507 16.842-16.768 16.842s-16.768-7.54-16.768-16.842 7.507-16.842 16.768-16.842c9.26 0 16.768 7.54 16.768 16.842z'/%3e%3c/svg%3e") no-repeat right top transparent;
    background-size: 178px;
  }
}
.profile-page__user-data-row {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem 0;
  align-items: center;
}
@media (min-width: 992px) {
  .profile-page__user-data-row {
    padding-right: 3rem;
  }
}
.profile-page__user-data-key {
  font-size: 0.75rem;
  font-weight: 300;
  width: 40%;
}
.profile-page__user-data-value {
  font-size: 1rem;
  font-weight: 600;
  flex-grow: 1;
}
.profile-page__btn {
  text-transform: uppercase;
}
.profile-page__btn_change-password {
  text-transform: none;
  color: #44aeca;
  text-decoration: underline;
}
.profile-page__btn_delete-account {
  text-transform: none;
  color: #fc1e1e;
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .profile-page__btn {
    width: 100%;
    display: block;
    margin-bottom: 2rem;
  }
}

.page-reboot__title {
  font-weight: 300;
  text-align: center;
  font-size: 1.5rem;
}
@media (max-width: 767.98px) {
  .page-reboot__title {
    font-size: 1.25rem;
  }
}
.page-reboot__description {
  text-align: center;
  color: #fd7e14;
  margin-top: 1.25rem;
  height: 2.5rem;
}
.page-reboot__image {
  background: url("../assets/images/device-reboot.svg") center center transparent no-repeat;
  height: 270px;
  margin-top: 2rem;
  background-size: contain;
}
@media (max-width: 767.98px) {
  .page-reboot__image {
    height: 170px;
  }
}
.page-reboot__image_progress {
  background-image: url("../assets/images/device.svg");
}
.page-reboot__progress-value {
  font-size: 1.5rem;
  color: #44aeca;
  font-weight: 600;
  text-align: center;
  margin: 3rem 0 2rem;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-reboot__progress-value {
    margin: 1.5rem 0;
  }
}
@media (min-width: 768px) {
  .page-reboot__btn {
    margin-top: 0 !important;
  }
}
.page-reboot__btn_cancel {
  border-color: #44aeca;
  color: #44aeca;
}
@media (min-width: 768px) {
  .page-reboot__btn_cancel {
    margin-right: 1.5rem;
  }
}

.app-recovery .form-control-recovery-code {
  max-width: 3rem;
  text-align: center;
}
.app-recovery .app-recovery-success {
  font-weight: 300;
  font-size: 1.5rem;
}
.app-recovery .app-recovery-success-smile {
  font-size: 5rem;
  color: #44aeca;
}

.app-report-daily .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 1.5rem 3rem;
  grid-template-areas: "pv-generation charged-battery ." "pv-generation pv-into-house household-consumption" "pv-generation pv-export-to-grid household-consumption" "grid-usage grid-into-house household-consumption" "grid-usage grid-to-batery household-consumption" "battery-usage battery-to-house household-consumption" "battery-usage battery-to-grid .";
}
@media (max-width: 767.98px) {
  .app-report-daily .grid-container {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 1fr;
    gap: 1rem 0.5rem;
    grid-template-areas: "pv-generation pv-generation pv-generation pv-generation pv-generation pv-generation" ". charged-battery charged-battery charged-battery charged-battery charged-battery" ". pv-into-house pv-into-house pv-into-house pv-into-house pv-into-house" ". pv-export-to-grid pv-export-to-grid pv-export-to-grid pv-export-to-grid pv-export-to-grid" "grid-usage grid-usage grid-usage grid-usage grid-usage grid-usage" ". grid-into-house grid-into-house grid-into-house grid-into-house grid-into-house" ". grid-to-batery grid-to-batery grid-to-batery grid-to-batery grid-to-batery" "battery-usage battery-usage battery-usage battery-usage battery-usage battery-usage" ". battery-to-house battery-to-house battery-to-house battery-to-house battery-to-house" ". battery-to-grid battery-to-grid battery-to-grid battery-to-grid battery-to-grid" "household-consumption household-consumption household-consumption household-consumption household-consumption household-consumption";
  }
}
.app-report-daily .grid-item-pv-generation {
  grid-area: pv-generation;
}
.app-report-daily .grid-item-charged-battery {
  grid-area: charged-battery;
}
.app-report-daily .grid-item-pv-into-house {
  grid-area: pv-into-house;
}
.app-report-daily .grid-item-pv-export-to-grid {
  grid-area: pv-export-to-grid;
}
.app-report-daily .grid-item-grid-usage {
  grid-area: grid-usage;
}
.app-report-daily .grid-item-grid-into-house {
  grid-area: grid-into-house;
}
.app-report-daily .grid-item-grid-to-batery {
  grid-area: grid-to-batery;
}
.app-report-daily .grid-item-battery-usage {
  grid-area: battery-usage;
}
.app-report-daily .grid-item-battery-to-house {
  grid-area: battery-to-house;
}
.app-report-daily .grid-item-battery-to-grid {
  grid-area: battery-to-grid;
}
.app-report-daily .grid-item-household-consumption {
  grid-area: household-consumption;
}
.app-report-daily .report-block-house-consumption {
  text-align: center;
  border-color: #fff;
}
.app-report-daily .report-block-house-consumption .report-block-value {
  justify-content: center;
}
@media (min-width: 768px) {
  .app-report-daily .report-block-battery-charge-from-pv .report-block-icon {
    font-size: 56px;
  }
  .app-report-daily .report-block-pv-consumption .report-block-icon {
    font-size: 56px;
  }
  .app-report-daily .report-block-pv-consumption .report-block-dot.right {
    top: calc(25% - 1.6rem);
  }
  .app-report-daily .report-block-pv-consumption:after {
    content: "";
    position: absolute;
    width: calc(50% + 1.5rem);
    height: 1px;
    background-color: #44aeca;
    left: calc(100% + 1.65rem);
    top: calc(25% - 1.05rem);
  }
  .app-report-daily .report-block-grid-export .report-block-icon {
    font-size: 60px;
  }
  .app-report-daily .report-block-grid-consumption .report-block-icon {
    font-size: 28px;
  }
  .app-report-daily .report-block-battery-charge-from-grid .report-block-icon {
    font-size: 56px;
  }
  .app-report-daily .report-block-battery-consumption .report-block-icon {
    font-size: 45px;
  }
  .app-report-daily .report-block-battery-consumption .report-block-dot.right {
    top: auto;
    bottom: calc(25% - 1.6rem);
  }
  .app-report-daily .report-block-battery-consumption:after {
    content: "";
    position: absolute;
    width: calc(50% + 1.5rem);
    height: 1px;
    background-color: #44aeca;
    left: calc(100% + 1.65rem);
    bottom: calc(25% - 1.05rem);
  }
  .app-report-daily .report-block-battery-to-grid .report-block-icon {
    font-size: 56px;
  }
  .app-report-daily .report-block-pv-generation .report-block-title {
    padding-top: 7rem !important;
  }
  .app-report-daily .report-block-pv-generation .report-block-icon {
    font-size: 120px !important;
    padding-top: 1rem;
  }
  .app-report-daily .report-block-pv-generation:after {
    content: "";
    position: absolute;
    height: calc(66.6666666667% + 1rem);
    width: 1px;
    background-color: #44aeca;
    right: -2.25rem;
    top: calc(16.6666666667% - 0.5rem);
  }
  .app-report-daily .report-block-grid-import:after {
    content: "";
    position: absolute;
    height: calc(50% + 0.75rem);
    width: 1px;
    background-color: #44aeca;
    right: -2.25rem;
    top: calc(25% - 0.375rem);
  }
  .app-report-daily .report-block-battery-usage:after {
    content: "";
    position: absolute;
    height: calc(50% + 0.75rem);
    width: 1px;
    background-color: #44aeca;
    right: -2.25rem;
    top: calc(25% - 0.375rem);
  }
  .app-report-daily .report-block-house-consumption {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: calc(100% - 8rem);
  }
  .app-report-daily .report-block-house-consumption .report-block-house-consumption-part {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .app-report-daily .report-block-house-consumption .report-block-title {
    padding-top: 5rem !important;
  }
  .app-report-daily .report-block-house-consumption .report-block-chart {
    flex-grow: 0 !important;
    justify-content: center;
  }
  .app-report-daily .report-block-house-consumption .report-block-value {
    padding-bottom: 4rem;
  }
  .app-report-daily .report-block-house-consumption .report-block-icon {
    font-size: 80px !important;
    padding-top: 1rem;
  }
  .app-report-daily .report-block-house-consumption .report-block-icon .icon-bolt {
    font-size: 42px;
    position: relative;
    top: -0.5em;
    left: -0.25em;
  }
}

@media (min-width: 992px) {
  .page-report-heatmap__report-type-link {
    font-size: 1.3125rem !important;
    font-weight: 300 !important;
    text-transform: none !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.page-report-heatmap__datetypeselect-container {
  display: flex;
  justify-content: space-between;
}
.page-report-heatmap__datetypeselect-block {
  flex-grow: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0.8725rem;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}
.page-report-heatmap__datetypeselect-block_active {
  border-color: #44aeca;
  color: #44aeca;
}

.app-login-page {
  position: relative;
}
.app-login-page:after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  right: 55px;
  width: 100%;
  height: 100%;
  max-width: 392px;
  max-height: 1024px;
  background: url("../assets/images/plant.png") center center transparent no-repeat;
}
@media (max-width: 1439.98px) {
  .app-login-page:after {
    right: -35px;
  }
}
@media (max-width: 1199.98px) {
  .app-login-page:after {
    right: 0;
  }
}
@media (max-width: 991.98px) {
  .app-login-page:after {
    background-position-x: 80px;
  }
}
@media (max-width: 767.98px) {
  .app-login-page:after {
    background-position-x: 80px;
  }
}
.app-login-page__wrapper {
  position: relative;
}
.app-login-page__loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.app-login-container {
  margin: auto;
}
.app-login-container .app-login-content {
  padding-top: 3rem;
  text-align: center;
}
@media (max-width: 1199.98px) {
  .app-login-container .app-login-content {
    padding-top: 2rem;
  }
}
.app-login-container .app-login-title {
  font-weight: 300;
  font-size: 2.125rem;
  margin: 1.5rem 0 1rem;
  letter-spacing: 0.014em;
  line-height: 1.177;
}
.app-login-container .app-login-form {
  margin: 3rem 0 2rem;
}
.app-login-container .app-login-form .form-control {
  text-align: center;
}
@media (max-width: 991.98px) {
  .app-login-container .app-login-form {
    margin: 2rem 0 2rem;
  }
}
.app-login-container .app-recovery-link {
  font-size: 0.75rem;
  line-height: 1.175;
  letter-spacing: 0.027em;
  top: -0.5rem;
  position: relative;
}
.app-login-container .app-social-block {
  position: relative;
}
.app-login-container .app-social-block .app-social-block-title {
  position: absolute;
  white-space: nowrap;
  left: -0.25em;
  font-weight: 300;
  letter-spacing: 0.02em;
}
.app-login-container .app-social-block .app-social-links {
  margin: 0;
  padding: 3rem 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.app-login-container .app-social-block .app-social-links .app-social-link {
  color: #44aeca;
  border-color: #44aeca;
  border-radius: 3.3rem;
  width: 3.3rem;
  height: 3.3rem;
  border: 1px solid #44aeca;
  font-size: 1.5rem;
  line-height: 3.3rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-login-container .app-social-block .app-social-links .app-social-link:hover {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.app-login-container .app-social-block .app-social-links .app-social-link:focus, .app-login-container .app-social-block .app-social-links .app-social-link.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 174, 202, 0.5);
}
.app-login-container .app-social-block .app-social-links .app-social-link.disabled, .app-login-container .app-social-block .app-social-links .app-social-link:disabled {
  color: #44aeca;
  background-color: transparent;
}
.app-login-container .app-social-block .app-social-links .app-social-link:not(:disabled):not(.disabled):active, .app-login-container .app-social-block .app-social-links .app-social-link:not(:disabled):not(.disabled).active, .show > .app-login-container .app-social-block .app-social-links .app-social-link.dropdown-toggle {
  color: #fff;
  background-color: #44aeca;
  border-color: #44aeca;
}
.app-login-container .app-social-block .app-social-links .app-social-link:not(:disabled):not(.disabled):active:focus, .app-login-container .app-social-block .app-social-links .app-social-link:not(:disabled):not(.disabled).active:focus, .show > .app-login-container .app-social-block .app-social-links .app-social-link.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 174, 202, 0.5);
}
.app-login-container .app-signup-link {
  letter-spacing: 0.02em;
  color: #44aeca;
  margin-top: 4rem;
  position: relative;
  display: block;
}
@media (max-width: 1199.98px) {
  .app-login-container .app-signup-link {
    margin-top: 3rem;
  }
}
@media (max-width: 767.98px) {
  .app-login-container .app-signup-link {
    margin-top: 1rem;
  }
}
.app-login-container .app-signup-link .app-signup-link-icon {
  position: relative;
  padding-bottom: 2rem;
}
.app-login-container .app-signup-link .app-signup-link-icon:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background-color: #44aeca;
  top: 0;
  left: 50%;
  margin-left: -10px;
}
.app-login-container .app-signup-link .app-signup-link-icon:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 20px;
  top: -9px;
  left: 50%;
  margin-left: -1px;
  background-color: #44aeca;
}
.app-login-container .app-signup-link:hover {
  color: #2b8097;
}
.app-login-container .app-signup-link:hover .app-signup-link-icon:before {
  background-color: #2b8097;
}
.app-login-container .app-signup-link:hover .app-signup-link-icon:after {
  background-color: #2b8097;
}

.app-signup-container .app-signup-title {
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
  line-height: 1.37;
  text-align: center;
}
.app-signup-container input {
  text-align: center;
}
.app-signup-container .invalid-feedback {
  text-align: center;
}
.app-signup-container .link {
  color: #44aeca;
  text-decoration: underline;
}
.app-signup-container .link:hover, .app-signup-container .link:active {
  color: #3091ab;
  text-decoration: none;
}
.app-signup-container .custom-control-label {
  letter-spacing: 0.02em;
  line-height: 1.37;
}
.app-signup-container .app-signin-link {
  margin: 1.5rem auto 1rem;
  text-align: center;
  display: block;
  padding: 1rem 0;
}

@media (min-width: 992px) {
  .page-settings__card {
    background-color: #000;
    background-clip: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2.5rem;
  }
}
.page-settings__card-title {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.37;
  letter-spacing: 0.02em;
  margin-bottom: 3rem;
}
.page-settings__group-title {
  color: #44aeca;
  font-size: 1.5rem;
  line-height: 1.37;
  font-weight: 300;
  margin: 3rem 0 1rem;
}
.page-settings__group-title_white {
  color: #fff;
}
.page-settings__note {
  font-size: 0.6875rem;
}
.page-settings__form-group {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  letter-spacing: 0.02em;
}
.page-settings__creatable-select {
  margin-left: 4rem;
}
@media (max-width: 767.98px) {
  .page-settings__creatable-select {
    margin-left: 0;
  }
}
.page-settings__input-prepend {
  width: 4rem;
  border-width: 0;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .page-settings__input-prepend {
    justify-content: center;
    padding: 0;
  }
}
.page-settings__form-control-suffix {
  font-weight: 300;
  font-size: 0.75rem;
  position: absolute;
  left: 3rem;
  top: 50%;
  margin-top: -0.375rem;
}
.page-settings__form-control-suffix_energy-rate {
  left: 8rem;
}
.page-settings__form-control {
  background-color: transparent;
  font-weight: 600;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-settings__form-control {
    padding: 0.875rem 0.4375rem;
    font-weight: 400;
  }
}
.page-settings__form-control[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.page-settings__form-control_clock {
  padding: 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-settings__form-control_clock .rc-time-picker-input {
    padding: 0.875rem 0.4375rem;
    font-weight: 400;
  }
}
.page-settings__form-control-buttons {
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(1.0625em + 1.75rem + 2px);
}
.page-settings__form-control-button {
  height: calc(1.0625rem + 1.75rem);
  background-color: #000;
  width: calc(1.0625rem + 1.75rem);
  border: 0 none;
  font-size: 1.375rem;
  color: #44aeca;
  padding: 0;
}
.page-settings__form-control-button[disabled] {
  opacity: 0.5;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page-settings__form-control-button_clock {
    display: none;
  }
}
.page-settings__slider-wrapper {
  margin-top: 3.5rem;
}
@media (max-width: 991.98px) {
  .page-settings__slider-wrapper {
    margin-top: 3rem;
  }
}
.page-settings__btn-save {
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .page-settings__btn-save {
    display: block;
    width: 100%;
  }
}
.page-settings__save-success {
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .page-settings__save-success {
    margin: 1rem 0 0;
    display: block;
  }
}

.page-settings-application-mode__schedule-title {
  margin: 0;
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__schedule-title {
    cursor: pointer;
    position: relative;
  }
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__schedule-title:after {
    transition: transform 0.25s ease-in;
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='%2344aeca' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") !important;
  }
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__schedule-title_collapsed:after {
    transform: rotateZ(180deg);
  }
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__schedule-detail {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
  }
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__schedule-detail_collapsed {
    max-height: 2000px;
  }
}
@media (max-width: 991.98px) {
  .page-settings-application-mode__form-control-button_clock {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .page-settings-application-mode__schedule-active-label {
    position: absolute;
    right: -1.8rem;
  }
}
@media (max-width: 767.98px) {
  .page-settings-application-mode__schedule-group:not(:last-of-type) {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .page-settings-application-mode__form-group-label {
    font-size: 1.25rem;
  }
}
.page-settings-application-mode .rc-time-picker-input[disabled],
.page-settings-application-mode .page-settings__form-control[disabled],
.page-settings-application-mode .page-settings__form-control-button[disabled] {
  opacity: 1;
}
.page-settings-application-mode__schedule-disabled {
  opacity: 0.5;
}
.page-settings-application-mode__schedule-disabled .page-settings__form-control-suffix {
  z-index: 1;
}

.app-start-container {
  margin-left: auto;
  margin-right: auto;
}
.app-start-container .app-start-content {
  padding-top: 3rem;
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 1.19em;
}
@media (max-width: 1199.98px) {
  .app-start-container .app-start-content {
    padding-top: 2rem;
  }
}
.app-start-container .no-device {
  height: 270px;
  background: url("../assets/images/no-device.svg") center center transparent no-repeat;
  margin: auto;
  background-size: contain;
}
@media (max-width: 767.98px) {
  .app-start-container .no-device {
    height: 220px;
  }
}
.app-start-container .app-start-title {
  font-weight: 300;
  font-size: 2.125rem;
  margin: 4.75rem auto 1.5rem;
  letter-spacing: 0.014em;
  line-height: 1.177;
  color: #44aeca;
  max-width: 15em;
}
@media (max-width: 991.98px) {
  .app-start-container .app-start-title {
    margin: 3.5rem auto 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .app-start-container .app-start-title {
    margin: 2.5rem auto 1rem;
    font-size: 1.75rem;
  }
}
.app-start-container .add-device-link {
  max-width: 300px;
  margin: 2.25rem auto 3.5rem;
  text-transform: uppercase;
  position: relative;
  padding-left: 60px;
}
.app-start-container .add-device-link .add-device-link-icon {
  position: absolute;
  left: 2.5rem;
  top: calc(50% - 1px);
}
.app-start-container .add-device-link .add-device-link-icon:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 50%;
  margin-left: -10px;
}
.app-start-container .add-device-link .add-device-link-icon:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 20px;
  top: -9px;
  left: 50%;
  margin-left: -1px;
  background-color: #fff;
}

.app-uikit .icon-list .icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  padding-bottom: 1rem;
}
.app-uikit .icon-list .icon-item .icon-name {
  position: absolute;
  bottom: 0.25rem;
  width: 100%;
  text-align: center;
}
.app-uikit .icon-list .icon-item:hover {
  font-size: 2em;
  color: #44aeca;
  border-color: #44aeca;
  cursor: pointer;
}
.app-uikit .icon-list .icon-item:hover .icon-name {
  font-size: 1rem;
}