@font-face {
	font-family: "icons";
	src: url("/static/media/icons.97bf88bd4bdb66fe2dac54ee30023183.eot?#iefix") format("embedded-opentype"),
url("/static/media/icons.e7c6ebdde69508735180f8d715f67ec6.woff") format("woff"),
url("/static/media/icons.b155d86f6e73df8294adceffc8fe1e7b.woff2") format("woff2"),
url("/static/media/icons.827cd1dc6e1795b237d94fba93b427d9.ttf") format("truetype"),
url("/static/media/icons.00573f85869c77a33b3c797810c85e87.svg#icons") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-angle-down:before {
	content: "\f101";
}
.icon-angle-left:before {
	content: "\f102";
}
.icon-angle-right:before {
	content: "\f103";
}
.icon-angle-up:before {
	content: "\f104";
}
.icon-arrow-square-down:before {
	content: "\f105";
}
.icon-arrow-square-up:before {
	content: "\f106";
}
.icon-backup-bolt:before {
	content: "\f107";
}
.icon-backup-cog:before {
	content: "\f108";
}
.icon-barcode:before {
	content: "\f109";
}
.icon-bars:before {
	content: "\f10a";
}
.icon-batter-search:before {
	content: "\f10b";
}
.icon-battery-cog:before {
	content: "\f10c";
}
.icon-battery-device:before {
	content: "\f10d";
}
.icon-battery-half:before {
	content: "\f10e";
}
.icon-battery-house:before {
	content: "\f10f";
}
.icon-battery-sun:before {
	content: "\f110";
}
.icon-battery-to-grid:before {
	content: "\f111";
}
.icon-bell:before {
	content: "\f112";
}
.icon-bluetooth:before {
	content: "\f113";
}
.icon-bolt-square:before {
	content: "\f114";
}
.icon-bolt-sun:before {
	content: "\f115";
}
.icon-calendar:before {
	content: "\f116";
}
.icon-camera:before {
	content: "\f117";
}
.icon-chart-bar:before {
	content: "\f118";
}
.icon-check:before {
	content: "\f119";
}
.icon-chevron-down:before {
	content: "\f11a";
}
.icon-chevron-left:before {
	content: "\f11b";
}
.icon-chevron-right:before {
	content: "\f11c";
}
.icon-chevron-up:before {
	content: "\f11d";
}
.icon-circle-slash:before {
	content: "\f11e";
}
.icon-clock-square:before {
	content: "\f11f";
}
.icon-clock:before {
	content: "\f120";
}
.icon-cloud:before {
	content: "\f121";
}
.icon-cog:before {
	content: "\f122";
}
.icon-comment:before {
	content: "\f123";
}
.icon-diagram-block:before {
	content: "\f124";
}
.icon-diagram:before {
	content: "\f125";
}
.icon-disconnect:before {
	content: "\f126";
}
.icon-display:before {
	content: "\f127";
}
.icon-dollar-sign:before {
	content: "\f128";
}
.icon-dollar-square:before {
	content: "\f129";
}
.icon-download:before {
	content: "\f12a";
}
.icon-email:before {
	content: "\f12b";
}
.icon-emoji-sad:before {
	content: "\f12c";
}
.icon-emoji-smile:before {
	content: "\f12d";
}
.icon-ethernet-4g:before {
	content: "\f12e";
}
.icon-ethernet-square:before {
	content: "\f12f";
}
.icon-exclamation-circle:before {
	content: "\f130";
}
.icon-exclamation-square:before {
	content: "\f131";
}
.icon-exclamation:before {
	content: "\f132";
}
.icon-file-4g:before {
	content: "\f133";
}
.icon-gavel:before {
	content: "\f134";
}
.icon-gid-signal:before {
	content: "\f135";
}
.icon-grid-alt:before {
	content: "\f136";
}
.icon-grid-coins:before {
	content: "\f137";
}
.icon-grid-export:before {
	content: "\f138";
}
.icon-grid-house:before {
	content: "\f139";
}
.icon-grid-import:before {
	content: "\f13a";
}
.icon-grid-money:before {
	content: "\f13b";
}
.icon-grid-sun:before {
	content: "\f13c";
}
.icon-grid-to-battery:before {
	content: "\f13d";
}
.icon-grid:before {
	content: "\f13e";
}
.icon-heatmap:before {
	content: "\f13f";
}
.icon-history:before {
	content: "\f140";
}
.icon-house-alt:before {
	content: "\f141";
}
.icon-house-bolt:before {
	content: "\f142";
}
.icon-house-double-roof:before {
	content: "\f143";
}
.icon-house-sun-bolt:before {
	content: "\f144";
}
.icon-house-sun:before {
	content: "\f145";
}
.icon-house:before {
	content: "\f146";
}
.icon-infinity:before {
	content: "\f147";
}
.icon-input-dots:before {
	content: "\f148";
}
.icon-list-square:before {
	content: "\f149";
}
.icon-list-two-column:before {
	content: "\f14a";
}
.icon-microchip:before {
	content: "\f14b";
}
.icon-minus:before {
	content: "\f14c";
}
.icon-mobile:before {
	content: "\f14d";
}
.icon-money:before {
	content: "\f14e";
}
.icon-network:before {
	content: "\f14f";
}
.icon-percent-bolt:before {
	content: "\f150";
}
.icon-piggy-bank:before {
	content: "\f151";
}
.icon-plug:before {
	content: "\f152";
}
.icon-plus:before {
	content: "\f153";
}
.icon-question-square:before {
	content: "\f154";
}
.icon-reboot:before {
	content: "\f155";
}
.icon-search:before {
	content: "\f156";
}
.icon-sign-in:before {
	content: "\f157";
}
.icon-signal-alt:before {
	content: "\f158";
}
.icon-signal:before {
	content: "\f159";
}
.icon-sliders-h:before {
	content: "\f15a";
}
.icon-socket:before {
	content: "\f15b";
}
.icon-sun-alt:before {
	content: "\f15c";
}
.icon-sun:before {
	content: "\f15d";
}
.icon-terminal:before {
	content: "\f15e";
}
.icon-th-alt:before {
	content: "\f15f";
}
.icon-th:before {
	content: "\f160";
}
.icon-times:before {
	content: "\f161";
}
.icon-trash:before {
	content: "\f162";
}
.icon-undo:before {
	content: "\f163";
}
.icon-user-alt:before {
	content: "\f164";
}
.icon-user-circle:before {
	content: "\f165";
}
.icon-user:before {
	content: "\f166";
}
.icon-wifi:before {
	content: "\f167";
}
