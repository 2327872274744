.app-login-page {
  position: relative;

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    //top: 75px;
    top: 0;
    right: 55px;
    width: 100%;
    height: 100%;
    max-width: 392px;
    max-height: 1024px;
    background: url($image_path + '/plant.png') center center transparent no-repeat;

    @include media-breakpoint-down(xl) {
      right: -35px;
    }
    @include media-breakpoint-down(lg) {
      right: 0;
    }
    @include media-breakpoint-down(md) {
      background-position-x: 80px;
    }
    @include media-breakpoint-down(sm) {
      background-position-x: 80px;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($black, .5);
    z-index: 100;
  }
}
.app-login-container {
  margin: auto;
  //height: 100vh;

  .app-login-content {
    padding-top: 3rem;
    text-align: center;

    @include media-breakpoint-down(lg) {
      padding-top: 2rem;
    }
  }

  .app-login-title {
    font-weight: 300;
    font-size: 2.125rem;
    margin: 1.5rem 0 1rem;
    letter-spacing: 0.014em;
    line-height: 1.177;
  }

  .app-login-form {
    margin: 3rem 0 2rem;

    .form-control {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      margin: 2rem 0 2rem;
    }
  }

  .app-recovery-link {
    font-size: .75rem;
    line-height: 1.175;
    letter-spacing: 0.027em;
    top: -.5rem;
    position: relative;
  }

  .app-social-block {
    position: relative;

    .app-social-block-title {
      position: absolute;
      white-space: nowrap;
      left: -.25em;
      font-weight: 300;
      letter-spacing: 0.02em;
    }

    .app-social-links {
      margin: 0;
      padding: 3rem 0 ;
      list-style: none;
      display: flex;
      justify-content: space-around;

      .app-social-link {
        $width: 3.3rem;
        @include button-outline-variant($input-border-color);
        border-radius: $width;
        width: $width;
        height: $width;
        border: $btn-border-width solid $input-border-color;
        font-size: 1.5rem;
        line-height: $width;
        color: $link-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .app-signup-link {
    letter-spacing: 0.02em;
    color: $primary;
    margin-top: 4rem;
    position: relative;
    display: block;

    @include media-breakpoint-down(lg) {
      margin-top: 3rem;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }

    .app-signup-link-icon {
      $width: 20px;
      $border: 2px;
      position: relative;
      padding-bottom: 2rem;

      &:before {
        position: absolute;
        content: "";
        width: $width;
        height: 2px;
        background-color: $primary;
        top: 0;
        left: 50%;
        margin-left: -1 * $width / 2;
      }
      &:after {
        position: absolute;
        content: "";
        width: $border;
        height: $width;
        top: -1 * $width / 2 + $border / 2;
        left: 50%;
        margin-left: -1 * $border / 2;
        background-color: $primary;
      }
    }

    @include hover {
      color: darken($primary, 15%);

      .app-signup-link-icon {
        &:before {
          background-color: darken($primary, 15%);
        }
        &:after {
          background-color: darken($primary, 15%);
        }
      }
    }
  }
}
