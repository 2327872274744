.page-monthly-report {
  $box-margin-left: nth($report-grid-components-spacing, 2);
  $box-margin-bottom: nth($report-grid-components-spacing, 1);

  .report-grid {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto 1fr auto;
      gap: 4rem 3rem;
      grid-template-areas:
        "battery-usage pv-generation grid-usage"
        "battery-usage-components pv-generation-components grid-usage-components"
        "household-consumption household-consumption household-consumption";

      @each $breakpoint, $gap in $report-grid-gaps {
        @include media-breakpoint-up($breakpoint) {
          gap: nth($gap, 2) nth($gap, 1);
        }
      }
      @include media-breakpoint-only(md) {
        gap: nth(map_get($report-grid-gaps, md), 1) nth(map_get($report-grid-gaps, md), 2);
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "battery-usage"
          "pv-generation-components"
          "pv-generation"
          "grid-usage-components"
          "grid-usage"
          "battery-usage-components";
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "battery-usage"
          "pv-generation-components"
          "pv-generation"
          "grid-usage-components"
          "grid-usage"
          "battery-usage-components";
      }

      &_battery-usage-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "battery-to-grid" "battery-to-house";
        @include media-breakpoint-up(lg) {
          margin-left: 3rem;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: $box-margin-bottom;
        }

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "battery-to-grid battery-to-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
        }

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          border: 1px solid $primary;
          @include media-breakpoint-down(md) {
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 2px);
          }

          @include media-breakpoint-only(md) {
            top: calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - 0.5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }

          $dotSize: map_get($report-block-dot-sizes, xl);
          @include media-breakpoint-up(lg) {
            top: -1 * nth(map_get($report-grid-gaps, lg), 2);
            left: calc(-1.5rem - 1px - #{$dotSize / 2});
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, lg), 2)}) + 0.5rem + 2px);
          }
          @include media-breakpoint-up(xl) {
            top: -1 * nth(map_get($report-grid-gaps, xl), 2);
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xl), 2)}) + 1rem - 2px);
          }
        }
      }

      &_pv-generation-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
          grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
        }

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          border: 1px solid $primary;
          @include media-breakpoint-down(md) {
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            height: calc(100% - (33% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 2px);
          }
          @include media-breakpoint-only(md) {
            top: calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(33% / 2 - 0.5rem);
            width: calc(100% - (33% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }
          $dotSize: map_get($report-block-dot-sizes, xl);
          @include media-breakpoint-up(lg) {
            top: -1 * nth(map_get($report-grid-gaps, lg), 2);
            left: calc(-1.5rem - 1px - #{$dotSize / 2});
            height: calc(100% - (33% / 2 - #{nth(map_get($report-grid-gaps, lg), 2)}) + 0.5rem + 3px);
          }
          @include media-breakpoint-up(xl) {
            top: -1 * nth(map_get($report-grid-gaps, xl), 2);
            height: calc(100% - (33% / 2 - #{nth(map_get($report-grid-gaps, xl), 2)}) + 1rem + 1px);
          }
        }
      }

      &_grid-usage-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "grid-to-battery" "grid-into-house";

        @include media-breakpoint-up(lg) {
          margin-bottom: $box-margin-bottom;
        }

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "grid-to-battery grid-into-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
        }

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          border: 1px solid $primary;
          @include media-breakpoint-down(md) {
            top: -1 * nth(map_get($report-grid-gaps, xs), 1);
            left: calc(-1.5rem - 1px);
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 2px);
          }

          @include media-breakpoint-only(md) {
            top: calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - 0.5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }

          $dotSize: map_get($report-block-dot-sizes, xl);
          @include media-breakpoint-up(lg) {
            top: -1 * nth(map_get($report-grid-gaps, lg), 2);
            left: calc(-1.5rem - 1px - #{$dotSize / 2});
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, lg), 2)}) + 0.5rem + 2px);
          }
          @include media-breakpoint-up(xl) {
            top: -1 * nth(map_get($report-grid-gaps, xl), 2);
            height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xl), 2)}) + 1rem - 2px);
          }
        }
      }
    }

    &__box {
      &_components {
        @include media-breakpoint-up(lg) {
          margin-left: $box-margin-left;
        }

        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }
      }

      &_household-consumption {
        @include media-breakpoint-up(lg) {
          margin-top: $box-margin-bottom / 4;
          margin-left: $box-margin-left;
          margin-right: $box-margin-left;
        }

        $dotSize: map_get($report-block-dot-sizes, lg);
        &:before {
          @include media-breakpoint-only(md) {
            $dotSize: map_get($report-block-dot-sizes, lg);
            @include report-block-make-dot(lg, $white, true);
            content: "";
            position: absolute;
            bottom: 0;
            right: 1.65rem;
            margin-right: -1 * $dotSize / 2;
            margin-bottom: -1 * $dotSize / 2;
            z-index: 1;
          }
          @include media-breakpoint-up(lg) {
            @include report-block-make-dot(lg, $primary, true);
            content: "";
            position: absolute;
            left: $box-margin-left / 2;
            top: -1 * $dotSize / 2;
            margin-left: -1 * $dotSize / 2;
            z-index: 1;
          }
        }
        &:after {
          @include media-breakpoint-up(lg) {
            @include report-block-make-dot(lg, $primary, true);
            content: "";
            position: absolute;
            left: calc(100% - 3.35rem);
            top: -1 * $dotSize / 2;
            margin-left: -1 * $dotSize / 2;
            z-index: 1;
          }
          @include media-breakpoint-up(xl) {
            left: calc(100% - 7rem + 4px);
          }
          @include media-breakpoint-up(xxl) {
            left: calc(100% - 16.5% + 3px);
          }
        }
      }

      &_battery-usage,
      &_pv-generation,
      &_grid-usage {
        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }

        &:before {
          $dotSize: map_get($report-block-dot-sizes, lg);
          @include report-block-make-dot(lg, $primary, true);
          content: "";
          position: absolute;
          left: 1.5rem;
          margin-left: -1 * $dotSize / 2;
          bottom: -1 * $dotSize / 2;
          z-index: 1;
          $dotSize: map_get($report-block-dot-sizes, xl);
          @include media-breakpoint-only(md) {
            @include report-block-make-dot(xl, $primary, false);
            left: auto;
            right: 50%;
            margin-right: -1 * $dotSize / 2;
            bottom: -1 * $dotSize / 2;
          }
          @include media-breakpoint-up(lg) {
            $dotSize: map_get($report-block-dot-sizes, xl);
            @include report-block-make-dot(xl, $primary, false);
            bottom: 0;
            left: $box-margin-left / 2;
            margin-bottom: -1 * $dotSize / 2;
          }
        }
        &:after {
          @include media-breakpoint-only(md) {
            $height: nth(map_get($report-grid-gaps, md), 1) / 3 * 2;
            content: "";
            position: absolute;
            bottom: -1 * $height;
            right: calc(50% - 1px);
            width: 1px;
            height: $height;
            border: 1px solid $primary;
          }
        }
      }

      &_battery-to-grid,
      &_battery-to-house,
      &_charged-battery,
      &_pv-export-to-grid,
      &_pv-into-house,
      &_grid-to-battery,
      &_grid-into-house {
        &:before {
          $dotSize: map_get($report-block-dot-sizes, base);
          @include media-breakpoint-down(md) {
            @include report-block-make-dot(base, $primary, true);
            content: "";
            position: absolute;
            left: -1 * $dotSize / 2;
            top: 50%;
            margin-top: -1 * $dotSize / 2;
            z-index: 1;
          }

          @include media-breakpoint-only(md) {
            left: 50%;
            top: 0;
            margin-left: -1 * $dotSize / 2;
          }

          @include media-breakpoint-up(lg) {
            content: "";
            position: absolute;
            left: -1 * $box-margin-left / 2 + 0.5rem;
            top: 50%;
            height: 1px;
            width: $box-margin-left / 2 - 0.5rem;
            border: 1px solid $primary;
          }
        }

        &:after {
          @include media-breakpoint-down(md) {
            $width: 1.5rem;
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: -1 * $width;
            width: $width;
            height: 1px;
            border: 1px solid $primary;
          }

          @include media-breakpoint-only(md) {
            $height: nth(map_get($report-grid-gaps, md), 1) / 3;
            left: 50%;
            width: 1px;
            height: $height;
            top: -1 * $height;
            margin-left: -1px;
          }
        }
      }
    }
  }

  .report-block {
    &__title {
      @include media-breakpoint-up(lg) {
        margin-right: 4rem;
      }

      &_lg {
        font-size: 1.5rem;

        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
          margin: 0 0 0 3.5rem;
          padding: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }

        @include media-breakpoint-only(lg) {
          margin-left: 4rem;
        }

        br {
          @include media-breakpoint-down(md) {
            display: none !important;
          }
        }
      }
    }

    &__description {
      &_lg {
        @include media-breakpoint-down(md) {
          margin-top: 1rem;
        }
      }

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }

    &__icon {
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }

      &_lg {
        @include media-breakpoint-down(lg) {
          font-size: 3rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 2rem;
          position: absolute;
        }
      }

      &_household-consumption {
        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
          top: map_get($report-block-spacing-y, xs);
        }
        @include media-breakpoint-down(sm) {
          left: map_get($report-block-spacing-y, xs);
        }
      }
    }

    &__energy {
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }

      &_household-consumption {
        @include media-breakpoint-only(md) {
          margin: 0;
        }
        @include media-breakpoint-down(sm) {
          margin-right: auto;
        }
      }
    }

    &_to-household-consumption {
      @include media-breakpoint-down(md) {
        border-color: $white;
      }

      &:before {
        $dotSize: map_get($report-block-dot-sizes, lg);
        @include media-breakpoint-up(lg) {
          @include report-block-make-dot(lg, $primary, true);
          content: "";
          position: absolute;
          left: $box-margin-left / 2;
          bottom: -1 * $dotSize / 2;
          margin-left: -1 * $dotSize / 2;
          z-index: 1;
        }
      }

      &:after {
        @include media-breakpoint-only(md) {
          $width: 1.5rem;
          content: "";
          position: absolute;
          top: 50%;
          margin-top: -1px;
          right: -1 * $width;
          width: $width;
          height: 1px;
          border: 1px solid $white;
        }
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          bottom: -1 * $box-margin-bottom - nth(map_get($report-grid-gaps, lg), 1) * 2 - $box-margin-bottom / 4 + 1rem;
          left: calc(#{$box-margin-left / 2} - 1px);
          width: 1px;
          height: $box-margin-bottom + nth(map_get($report-grid-gaps, lg), 1) * 2 + $box-margin-bottom / 4 - 1rem;
          border: 1px solid $primary;
        }
        @include media-breakpoint-up(xl) {
          content: "";
          position: absolute;
          bottom: -1 * $box-margin-bottom - nth(map_get($report-grid-gaps, xl), 1) * 2 - $box-margin-bottom / 4 + 1rem;
          left: calc(#{$box-margin-left / 2} - 1px);
          width: 1px;
          height: $box-margin-bottom + nth(map_get($report-grid-gaps, xl), 1) * 2 + $box-margin-bottom / 4 - 1rem;
          border: 1px solid $primary;
        }
      }
    }

    &_pv-into-house {
      &:after {
        @include media-breakpoint-up(lg) {
          bottom: 0 - nth(map_get($report-grid-gaps, lg), 1) * 2 - $box-margin-bottom / 4 + 1rem;
          height: nth(map_get($report-grid-gaps, lg), 1) * 2 + $box-margin-bottom / 4 - 1rem;
        }
        @include media-breakpoint-up(xl) {
          bottom: 0 - nth(map_get($report-grid-gaps, xl), 1) * 2 - $box-margin-bottom / 4 + 1rem;
          height: nth(map_get($report-grid-gaps, xl), 1) * 2 + $box-margin-bottom / 4 - 1rem;
        }
      }
    }

    &_household-consumption {
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &:before {
        @include media-breakpoint-only(md) {
          $height: 100% * 14;
          content: "";
          position: absolute;
          right: 1.5rem;
          margin-left: -1px;
          bottom: calc(-1 * #{$height} + 1rem);
          height: calc(#{$height} - 1rem);
          width: 1px;
          border: 1px solid $white;
        }

        $dotSize: map_get($report-block-dot-sizes, lg);
        @include media-breakpoint-up(lg) {
          @include report-block-make-dot(lg, $primary, true);
          content: "";
          position: absolute;
          left: calc(50% - 0.15rem);
          top: -1 * $dotSize / 2;
          margin-left: -1 * $dotSize / 2;
          z-index: 1;
        }
        @include media-breakpoint-up(xl) {
          left: calc(50% - 2rem + 3px);
        }
        @include media-breakpoint-up(xxl) {
          left: calc(50% - 4.5rem + 3px);
        }
      }
    }
  }
}
