@mixin report-block-make-dot($size: base, $color: $primary, $fill: true) {
  $dotSize: map_get($report-block-dot-sizes, $size);
  @if $dotSize == null {
    @error Error dot size $size;
  } @else {
    width: $dotSize;
    height: $dotSize;
    border-radius: $dotSize;
    @if $fill {
      background-color: $color;
    } @else {
      border: 0.375rem solid $color;
      background-color: $black;
    }
  }
}