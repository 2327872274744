@import "variables";

.report-grid {
  &__container {
    display: grid;

    @each $breakpoint, $gap in $report-grid-gaps {
      @include media-breakpoint-up($breakpoint) {
        gap: $gap;
      }
    }
  }

  &__box {
    position: relative;

    @each $box in $report-grid-boxes {
      &_#{$box} {
        grid-area: #{$box};
      }
    }

    &_components {
      display: grid;
      @each $breakpoint, $gap in $report-grid-gaps {
        @include media-breakpoint-up($breakpoint) {
          gap: nth($gap, 1);
        }
      }
    }
  }
}