@import "variables";
@import "mixins";

.report-block {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $primary;
  height: 100%;

  @each $breakpoint, $spacing-y in $report-block-spacing-y {
    @include media-breakpoint-up($breakpoint) {
      padding: $spacing-y;
    }
  }

  &_lg {
    @each $breakpoint, $spacing-y in $report-block-spacing-lg-y {
      @include media-breakpoint-up($breakpoint) {
        padding: $spacing-y;
      }
    }
  }

  &_household-consumption {
    border-color: $white;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    font-size: 1.35rem;
    line-height: 1.17;
    letter-spacing: 0.02em;
    flex-grow: 1;

    @include media-breakpoint-down(lg) {
      font-size: 1.1rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1rem;
      padding-right: 2rem;
    }

    &_lg {
      font-weight: 300;
      font-size: 2rem;
      padding-top: 0;
      margin-left: 6rem;
      flex-grow: unset;

      @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
        padding-top: 1rem;
      }
    }

    &_household-consumption {
      margin-left: 1rem;
    }
  }

  &__description {
    font-size: 0.6785rem;
    line-height: 1.62;
    letter-spacing: 0.03em;
    color: rgba($white, .5);
    margin: 4rem 0 1.5rem;
    height: 3.5rem;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      margin: 1rem 0 0.5rem;
      height: auto;
      overflow: inherit;
    }

    @include media-breakpoint-only(md) {
      margin: 1rem 0;
    }

    &_lg {
      margin-top: 2rem;

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }

    &_household-consumption {
      margin: 0 2rem;
      padding-right: 0;
      height: auto;
    }
  }

  &__energy {
    font-weight: 600;
    font-size: 2.75rem;
    letter-spacing: 0.01em;
    color: $primary;
    margin-bottom: -.25em;
    display: flex;
    align-items: baseline;

    @include media-breakpoint-down(xl) {
      font-size: 2.25rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }

    &_lg {
      font-size: 4.625rem;
      //padding-top: 2rem;
      flex-grow: 0;

      @include media-breakpoint-down(lg) {
        font-size: 3.5rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &_household-consumption {
      padding: 0;
    }
  }

  &__energy-unit {
    font-weight: 300;
    font-size: 1.375rem;
    letter-spacing: 0.02em;
    color: $primary;
    display: inline-block;
    margin-left: .75rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.15rem;
    }

    @include media-breakpoint-down(md) {
      font-size: .875rem;
    }
  }

  &__icon {
    font-size: 2.5rem;
    position: absolute;
    @each $breakpoint, $spacing-y in $report-block-spacing-y {
      @include media-breakpoint-up($breakpoint) {
        right: $spacing-y;
        top: $spacing-y;
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 2.15rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

    &_lg {
      font-size: 4rem;
      top: auto;
      right: auto;

      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &_household-consumption {
      font-size: 7rem;
      position: static;

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
  }

  &__chart {
    position: absolute;

    @each $breakpoint, $spacing-y in $report-block-spacing-y {
      @include media-breakpoint-up($breakpoint) {
        right: $spacing-y;
        bottom: $spacing-y;
      }
    }

    &_lg {
      position: static;
      flex-grow: 1;
      display: flex;
      align-items: flex-end;

      @include media-breakpoint-down(md) {
        position: absolute;
        flex-grow: 0;
      }
    }
  }
}