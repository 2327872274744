.device-info {
  .device-image {
    background-position: center center;
    background-color: transparent;
    width: 315px;
    height: 342px;
    margin: auto;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      height: 250px;
    }

    &.b-1 {
      background-image: url($image_path + '/devices/energizer-hp6-1-off.png');
      &.on {
        background-image: url($image_path + '/devices/energizer-hp6-1-on.png');
      }
    }
    &.b-2 {
      background-image: url($image_path + '/devices/energizer-hp6-2-off.png');
      &.on {
        background-image: url($image_path + '/devices/energizer-hp6-2-on.png');
      }
    }
    &.b-3 {
      background-image: url($image_path + '/devices/energizer-hp6-3-off.png');
      &.on {
        background-image: url($image_path + '/devices/energizer-hp6-3-on.png');
      }
    }
    &.b-4 {
      background-image: url($image_path + '/devices/energizer-hp6-4-off.png');
      &.on {
        background-image: url($image_path + '/devices/energizer-hp6-4-on.png');
      }
    }
  }

  .device-soc-value {
    border: 1px solid $primary;
    text-align: center;
    padding: 1rem 0;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.6;
    height: 100%;
    position: relative;

    .device-soc-fill {
      position: absolute;
      left: 2px;
      bottom: 2px;
      background: #0093b9;
      background: linear-gradient(0deg, $black, #0093b9 100%);
      width: calc(100% - 4px);
    }

    .device-soc-percent {
      position: relative;
    }
  }

  .info-block {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1.5rem 1.125rem;
    height: 164px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.25rem;

    @include media-breakpoint-down(xl) {
      font-size: 1.1rem;
    }

    @include media-breakpoint-between(lg, xl) {
      padding: 1.25rem 0.75rem;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
    }

    .info-block-title {
      color: rgba($white, .5);

      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    .info-block-value {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;

      &_small {
        font-size: 80%;
      }
      &_extra-small {
        font-size: 70%;
      }
    }

    .info-block-value-badge {
      background-color: #6E757B;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 2;
      display: inline-block;
      text-align: center;
      min-width: 1.5rem;
      margin-right: .5rem;
    }

    .info-block-small {
      font-size: 0.75rem;
    }

    .device-net-status {
      $width: 1.75rem;

      position: relative;
      padding-left: 2.5rem;
      line-height: $width;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - #{$width / 2});
        border-radius: $width;
        width: $width;
        height: $width;
        border-width: 3px;
        border-style: solid;
      }

      &.online {
        &:after {
          border-color: $green;
        }
      }
      &.offline {
        &:after {
          border-color: $red;
        }
      }
    }

    .device-state {
      padding: .5rem;
      border: 1px solid $primary;
      display: inline-block;
    }
  }
}