$datepicker__background-color: $white;
$datepicker__border-color: $white;
$datepicker__highlighted-color: $primary;
$datepicker__muted-color: rgba($gray-800, 0.2);
$datepicker__selected-color: $primary;
$datepicker__text-color: $black;
$datepicker__header-color: $black;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 0;
$datepicker__day-margin: 0.4rem;
$datepicker__day-margin-xs: 0.2rem;
$datepicker__font-size: 1rem;
$datepicker__font-family: $font-family-base;
$datepicker__item-size: 2rem;
$datepicker__margin: 0 1rem 1rem;
$datepicker__margin-xs: 0 0.4rem 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 12px;

@import "~react-datepicker/src/stylesheets/datepicker";

.react-datepicker__day-name {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.033em;
}

.react-datepicker__navigation--previous {
  border-right-color: $primary;

  &:hover {
    border-right-color: darken($primary, 10%);
  }
}

.react-datepicker__month {
  @include media-breakpoint-down(sm) {
    margin: $datepicker__margin-xs;
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @include media-breakpoint-down(sm) {
    margin: $datepicker__day-margin-xs;
  }
}

.react-datepicker__navigation {
  top: 25px;
}

.react-datepicker__navigation--next {
  border-left-color: $primary;

  &:hover {
    border-left-color: darken($primary, 10%);
  }
}

.react-datepicker__quarter,
.react-datepicker__year-container-text {
  font-size: 1.0625rem;
  font-weight: 600;
  padding: 1rem 0.5rem;
  margin: 0.25rem;
  border: 1px solid $primary;
  color: $primary;

  &--selected,
  &--in-selecting-range,
  &--in-range {
    color: #e9ecef;
  }
}

.react-datepicker__day {
  font-weight: 400;
}

.react-datepicker__header {
  padding-top: 1.5rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &--selected,
  &--in-selecting-range,
  &--in-range {
    color: $black;
  }
}

.react-datepicker__year-container {
  margin: 1rem;
}

.react-datepicker__year-container-text {
  box-sizing: content-box;

  &--disabled {
    border-color: $datepicker__muted-color;
    color: $datepicker__muted-color;
  }
}
